"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "QuickReplyService", {
    enumerable: true,
    get: function() {
        return QuickReplyService;
    }
});
const _quickReply = require("../../models/quickReply");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
class QuickReplyService {
    async fetchMessages({ offset, limit = 20, search }) {
        const { data } = await this.http.get(`/quickReplies?offset=${offset}&limit=${limit}${search ? `&keyword=${search}` : ""}`);
        return _object_spread_props(_object_spread({}, data), {
            docs: data.docs.map((elem)=>new _quickReply.QuickReply(elem))
        });
    }
    async createMessageOrEdit({ _id, name, message: text }) {
        await this.http.post("/addQuickReply", {
            _id,
            name,
            text
        });
    }
    async deleteMessage(id) {
        await this.http.post("/deleteQuickReply", {
            quickReplyID: id
        });
    }
    async buildTextForReservation(reservationID, message) {
        const { data } = await this.http.post(`/quickReply/buildText/${reservationID}`, {
            text: message
        });
        return data.message;
    }
    async fetchSuggestion(reservationID, config) {
        const { data } = await this.http.post(`/quickReply/autoComplete/${reservationID}`, {}, config);
        return data.message;
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
