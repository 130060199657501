"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "BatteryLowIcon", {
    enumerable: true,
    get: function() {
        return BatteryLowIcon;
    }
});
const _batterylowsvg = require("../../../assets/battery-low.svg");
const _iconMaker = require("./iconMaker");
const BatteryLowIcon = (0, _iconMaker.iconMaker)(_batterylowsvg.ReactComponent, "BatteryLowIcon");
