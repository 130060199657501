"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "script", {
    enumerable: true,
    get: function() {
        return script;
    }
});
const script = (mode)=>{
    const { documentElement } = document;
    function getSystemColorMode() {
        return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    }
    try {
        const isSystem = mode === "system";
        const theme = isSystem ? getSystemColorMode() : mode;
        documentElement.classList.remove(theme === "light" ? "dark" : "light");
        documentElement.classList.add(theme);
        documentElement.style.colorScheme = theme;
    } catch (e) {
        console.error(e);
    }
};
