"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ChevronDownIcon", {
    enumerable: true,
    get: function() {
        return ChevronDownIcon;
    }
});
const _chevrondowniconsvg = require("../../../assets/chevron-down-icon.svg");
const _iconMaker = require("./iconMaker");
const ChevronDownIcon = (0, _iconMaker.iconMaker)(_chevrondowniconsvg.ReactComponent, "ChevronDownIcon");
