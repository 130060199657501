"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    REGEX_EMAIL: function() {
        return REGEX_EMAIL;
    },
    default: function() {
        return _default;
    }
});
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
function validateEmail(email) {
    // regular expression for email validation
    const pattern = REGEX_EMAIL;
    return pattern.test(email);
}
const _default = validateEmail;
