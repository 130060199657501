"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getReservationName", {
    enumerable: true,
    get: function() {
        return getReservationName;
    }
});
function getReservationName(reservation) {
    return reservation.fullName || reservation.source;
}
