"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _contexts = require("../../contexts");
const useListingsByIDs = (listingIDs)=>{
    const { listings } = (0, _react.useContext)(_contexts.UserContext);
    return (0, _react.useMemo)(()=>{
        if (!listingIDs) {
            return null;
        }
        return listings.filter((elem)=>{
            return Array.isArray(listingIDs) ? listingIDs.includes(elem._id) : listingIDs === elem._id;
        });
    }, [
        listingIDs,
        listings
    ]);
};
const _default = useListingsByIDs;
