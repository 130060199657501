"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    buildChannelConnectorURL: function() {
        return buildChannelConnectorURL;
    },
    buildLocks2Accounts: function() {
        return buildLocks2Accounts;
    },
    getAccountID: function() {
        return getAccountID;
    },
    isDisconnectedAccount: function() {
        return isDisconnectedAccount;
    },
    mapListings2Accounts: function() {
        return mapListings2Accounts;
    },
    setListingsIntoAccounts: function() {
        return setListingsIntoAccounts;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function mapListings2Accounts(accounts, allListings) {
    return accounts.map((account)=>{
        const { type } = account;
        if (type === "Seam" || type === "August") {
            return _object_spread_props(_object_spread({}, account), {
                locks: "locks" in account ? account.locks : [],
                listings: []
            });
        }
        const listings = allListings.reduce((result, listing)=>{
            var _listing_channels_type;
            if (((_listing_channels_type = listing.channels[type]) === null || _listing_channels_type === void 0 ? void 0 : _listing_channels_type.accountID) !== account._id) {
                return result;
            }
            // Fill out some information for listing. Some function will no longer without these info
            // Make sure listingEnabled returns true or false because on
            // old accounts, listingEnabled can be null when the listing is enabled
            listing.listingEnabled = listing.listingEnabled !== false;
            listing.airbnbUserID = account.airbnbUserID;
            result.push(listing);
            return result;
        }, []);
        return _object_spread_props(_object_spread({}, account), {
            locks: [],
            listings
        });
    });
}
function buildLocks2Accounts(accounts, locks) {
    return accounts.map((account)=>{
        const result = locks.reduce((result, lock)=>{
            if (account._id === lock.accountID) {
                result.push(lock);
            }
            return result;
        }, []);
        return _object_spread_props(_object_spread({}, account), {
            listings: "listings" in account ? account.listings : [],
            locks: result
        });
    });
}
function setListingsIntoAccounts(accounts, allListings) {
    return accounts.map((account)=>{
        const { type } = account;
        if (type === "Seam" || type === "August") {
            return _object_spread_props(_object_spread({}, account), {
                locks: [],
                listings: []
            });
        }
        const listings = allListings.reduce((result, listing)=>{
            var _listing_channels_type;
            if (((_listing_channels_type = listing.channels[type]) === null || _listing_channels_type === void 0 ? void 0 : _listing_channels_type.accountID) !== account._id) {
                return result;
            }
            // Fill out some information for listing. Some function will no longer without these info
            // Make sure listingEnabled returns true or false because on
            // old accounts, listingEnabled can be null when the listing is enabled
            listing.listingEnabled = listing.listingEnabled !== false;
            listing.airbnbUserID = account.airbnbUserID;
            result.push(listing);
            return result;
        }, []);
        return _object_spread_props(_object_spread({}, account), {
            locks: [],
            listings
        });
    });
}
function getAccountID(account) {
    var _account_booking;
    return account.type === "Airbnb" && account.airbnbUserID || account.type === "Booking" && (account === null || account === void 0 ? void 0 : (_account_booking = account.booking) === null || _account_booking === void 0 ? void 0 : _account_booking.propertyID) || undefined;
}
function buildChannelConnectorURL(accountID, path) {
    const baseUrl = `/settings/channels/${accountID}`;
    if (path) {
        const encodedPath = encodeURIComponent(path);
        return `${baseUrl}?path=${encodedPath}`;
    }
    return baseUrl;
}
function isDisconnectedAccount(account) {
    return account.lastLoginAttemptSuccessful === false;
}
