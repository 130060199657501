"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const defaultPaginationInfo = {
    hasMore: true,
    loading: true,
    offset: 0,
    data: undefined,
    total: 0,
    page: 1,
    hasPrevPage: false,
    paginationNumbers: []
};
function usePaginatedFetch(request, limit, deps = [], option = {
    infinite: true
}) {
    // passing this would cause no rendering as `request` change
    // const requestRef = useRef(request);
    const currentUpdate = (0, _react.useRef)();
    const [info, setInfo] = (0, _react.useState)(defaultPaginationInfo);
    const update = (0, _react.useCallback)(async (offset, active = true)=>{
        setInfo((prev)=>_object_spread_props(_object_spread({}, prev), {
                loading: true,
                offset
            }));
        const paginationResult = await request({
            limit,
            offset
        });
        if (!paginationResult) {
            return;
        }
        const result = paginationResult.docs || [];
        // if (!mounted) {
        //   return;
        // }
        const getInfiniteData = (prevData)=>{
            return offset === 0 ? result : [].concat(prevData || [], result);
        };
        if (!active) {
            return;
        }
        setInfo((prev)=>_object_spread_props(_object_spread({}, prev), {
                loading: false,
                hasMore: result.length === limit,
                total: paginationResult.totalDocs,
                hasPrevPage: paginationResult.hasPrevPage,
                page: paginationResult.page,
                data: option.infinite ? getInfiniteData(prev.data) : result
            }));
    }, [
        limit,
        option.infinite,
        request
    ]);
    const refresh = (0, _react.useCallback)(async (active = true)=>{
        // Keep the data while refreshing looks like a better UX
        // const {data, ...defaultValues} = defaultPaginationInfo;
        setInfo((prev)=>_object_spread({}, prev, defaultPaginationInfo));
        currentUpdate.current = update(0, active);
        return currentUpdate.current;
    }, [
        update
    ]);
    (0, _react.useEffect)(()=>{
        let active = true;
        refresh(active);
        return ()=>{
            active = false;
        };
    }, [
        ...deps,
        refresh
    ]);
    const fetchPage = async (page)=>{
        if (currentUpdate.current) {
            await currentUpdate.current;
        }
        currentUpdate.current = update((page - 1) * limit);
        return currentUpdate.current;
    };
    const fetchMore = async ()=>{
        if (currentUpdate.current) {
            await currentUpdate.current;
        }
        if (info.hasMore) {
            currentUpdate.current = update(info.offset + limit);
        }
        return currentUpdate.current;
    };
    const fetchPrevious = async ()=>{
        if (currentUpdate.current) {
            await currentUpdate.current;
        }
        if (info.hasPrevPage) {
            currentUpdate.current = update(info.offset - limit);
        }
        return currentUpdate.current;
    };
    return _object_spread_props(_object_spread({}, info), {
        fetchMore,
        refresh,
        fetchPage,
        fetchPrevious
    });
}
const _default = usePaginatedFetch;
