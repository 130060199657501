"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useLoadScript", {
    enumerable: true,
    get: function() {
        return useLoadScript;
    }
});
const _react = require("react");
// Cached script statuses
const cachedScriptStatuses = new Map();
function getScriptNode(src) {
    const node = document.querySelector(`script[src="${src}"]`);
    const status = node === null || node === void 0 ? void 0 : node.getAttribute("data-status");
    return {
        node,
        status
    };
}
function useLoadScript(src, options) {
    const [status, setStatus] = (0, _react.useState)(()=>{
        if (!src || (options === null || options === void 0 ? void 0 : options.shouldPreventLoad)) {
            return "idle";
        }
        if (typeof window === "undefined") {
            // SSR Handling - always return 'loading'
            return "loading";
        }
        var _cachedScriptStatuses_get;
        return (_cachedScriptStatuses_get = cachedScriptStatuses.get(src)) !== null && _cachedScriptStatuses_get !== void 0 ? _cachedScriptStatuses_get : "loading";
    });
    (0, _react.useEffect)(()=>{
        if (!src || (options === null || options === void 0 ? void 0 : options.shouldPreventLoad)) {
            return;
        }
        const cachedScriptStatus = cachedScriptStatuses.get(src);
        if (cachedScriptStatus === "ready" || cachedScriptStatus === "error") {
            // If the script is already cached, set its status immediately
            setStatus(cachedScriptStatus);
            return;
        }
        // Fetch existing script element by src
        // It may have been added by another instance of this hook
        const script = getScriptNode(src);
        let scriptNode = script.node;
        if (!scriptNode) {
            // Create script element and add it to document body
            scriptNode = document.createElement("script");
            scriptNode.src = src;
            scriptNode.async = true;
            if (options === null || options === void 0 ? void 0 : options.id) {
                scriptNode.id = options.id;
            }
            scriptNode.setAttribute("data-status", "loading");
            document.body.appendChild(scriptNode);
            // Store status in attribute on script
            // This can be read by other instances of this hook
            const setAttributeFromEvent = (event)=>{
                const scriptStatus = event.type === "load" ? "ready" : "error";
                scriptNode === null || scriptNode === void 0 ? void 0 : scriptNode.setAttribute("data-status", scriptStatus);
            };
            scriptNode.addEventListener("load", setAttributeFromEvent);
            scriptNode.addEventListener("error", setAttributeFromEvent);
        } else {
            var _script_status, _ref;
            // Grab existing script status from attribute and set to state.
            setStatus((_ref = (_script_status = script.status) !== null && _script_status !== void 0 ? _script_status : cachedScriptStatus) !== null && _ref !== void 0 ? _ref : "loading");
        }
        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event)=>{
            const newStatus = event.type === "load" ? "ready" : "error";
            setStatus(newStatus);
            cachedScriptStatuses.set(src, newStatus);
        };
        // Add event listeners
        scriptNode.addEventListener("load", setStateFromEvent);
        scriptNode.addEventListener("error", setStateFromEvent);
        // Remove event listeners on cleanup
        return ()=>{
            if (scriptNode) {
                scriptNode.removeEventListener("load", setStateFromEvent);
                scriptNode.removeEventListener("error", setStateFromEvent);
            }
            if (scriptNode && (options === null || options === void 0 ? void 0 : options.removeOnUnmount)) {
                scriptNode.remove();
                cachedScriptStatuses.delete(src);
            }
        };
    }, [
        src,
        options === null || options === void 0 ? void 0 : options.shouldPreventLoad,
        options === null || options === void 0 ? void 0 : options.removeOnUnmount,
        options === null || options === void 0 ? void 0 : options.id
    ]);
    return status;
}
