"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return mergeRefs;
    }
});
function mergeRefs(...inputRefs) {
    const filteredInputRefs = inputRefs.filter(Boolean);
    if (filteredInputRefs.length <= 1) {
        const firstRef = filteredInputRefs[0];
        return firstRef !== null && firstRef !== void 0 ? firstRef : null;
    }
    return function mergedRefs(ref) {
        filteredInputRefs.forEach((inputRef)=>{
            if (typeof inputRef === "function") {
                inputRef(ref);
            } else if (inputRef) {
                inputRef.current = ref;
            }
        });
    };
}
