"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "FileEditIcon", {
    enumerable: true,
    get: function() {
        return FileEditIcon;
    }
});
const _fileediticonsvg = require("../../../assets/file-edit-icon.svg");
const _iconMaker = require("./iconMaker");
const FileEditIcon = (0, _iconMaker.iconMaker)(_fileediticonsvg.ReactComponent, "FileEditIcon");
