"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "SquarePlusIcon", {
    enumerable: true,
    get: function() {
        return SquarePlusIcon;
    }
});
const _squareplusiconsvg = require("../../../assets/square-plus-icon.svg");
const _iconMaker = require("./iconMaker");
const SquarePlusIcon = (0, _iconMaker.iconMaker)(_squareplusiconsvg.ReactComponent, "SquarePlusIcon", "stroke");
