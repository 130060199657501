"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _griddotssvg = require("../../../assets/grid-dots.svg");
const _iconMaker = require("./iconMaker");
const GridDotsIcon = (0, _iconMaker.iconMaker)(_griddotssvg.ReactComponent, "GridDotsIcon");
const _default = GridDotsIcon;
