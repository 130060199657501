"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    BETA_HOST: function() {
        return BETA_HOST;
    },
    DATE_FORMAT: function() {
        return DATE_FORMAT;
    },
    PROD_HOST: function() {
        return PROD_HOST;
    },
    UTC_TIMEZONE: function() {
        return UTC_TIMEZONE;
    },
    apiKeyNames: function() {
        return apiKeyNames;
    },
    bookingWindowValues: function() {
        return bookingWindowValues;
    },
    channels: function() {
        return channels;
    },
    dayInMs: function() {
        return dayInMs;
    },
    disabledMessageStatus: function() {
        return disabledMessageStatus;
    },
    enabledMessageStatus: function() {
        return enabledMessageStatus;
    },
    eventReservationStatus: function() {
        return eventReservationStatus;
    },
    failedMessageStatus: function() {
        return failedMessageStatus;
    },
    helpLink: function() {
        return helpLink;
    },
    hourInMs: function() {
        return hourInMs;
    },
    integrations: function() {
        return integrations;
    },
    internal: function() {
        return internal;
    },
    isValidStatus: function() {
        return isValidStatus;
    },
    languages: function() {
        return languages;
    },
    listingStatuses: function() {
        return listingStatuses;
    },
    maxImagesOnListing: function() {
        return maxImagesOnListing;
    },
    maxImagesOnMessageRule: function() {
        return maxImagesOnMessageRule;
    },
    messageCompleteStatus: function() {
        return messageCompleteStatus;
    },
    messageStatus: function() {
        return messageStatus;
    },
    minuteInMs: function() {
        return minuteInMs;
    },
    noop: function() {
        return noop;
    },
    originalChannels: function() {
        return originalChannels;
    },
    permanentlyFailedMessageStatus: function() {
        return permanentlyFailedMessageStatus;
    },
    priceSourceValues: function() {
        return priceSourceValues;
    },
    providerChannels: function() {
        return providerChannels;
    },
    rateNames: function() {
        return rateNames;
    },
    rateTypes: function() {
        return rateTypes;
    },
    reservationSources: function() {
        return reservationSources;
    },
    reservationStatuses: function() {
        return reservationStatuses;
    },
    secondInMs: function() {
        return secondInMs;
    },
    successMessageStatus: function() {
        return successMessageStatus;
    },
    supportEmail: function() {
        return supportEmail;
    },
    systemStatusLink: function() {
        return systemStatusLink;
    },
    temporaryFailedMessageStatus: function() {
        return temporaryFailedMessageStatus;
    },
    twoYearsInDays: function() {
        return twoYearsInDays;
    },
    validListingStatuses: function() {
        return validListingStatuses;
    },
    yearInDays: function() {
        return yearInDays;
    }
});
const DATE_FORMAT = "YYYY-MM-DD";
const originalChannels = [
    "Airbnb",
    "HomeAway",
    "Booking",
    "Houfy"
];
const providerChannels = [
    ...originalChannels,
    "ChannelConnector"
];
const channels = providerChannels;
const integrations = [
    "August",
    "Seam"
];
const internal = "internal";
const reservationSources = [
    ...providerChannels,
    internal
];
const validListingStatuses = [
    "listed",
    "unlisted"
];
const listingStatuses = [
    ...validListingStatuses,
    "not found",
    "pending"
];
const priceSourceValues = [
    "Amount",
    "HomeAway",
    "Houfy",
    "PriceLabs",
    "Beyond Pricing"
];
const bookingWindowValues = [
    30,
    45,
    60,
    75,
    90,
    180,
    365,
    547,
    0
];
const reservationStatuses = [
    "inquiry",
    "not_possible",
    "preapproved",
    "denied",
    "timedout",
    "pending",
    "checkpoint",
    "awaiting_payment",
    "accepted",
    "cancelled",
    "needs_update",
    "deny",
    "timeout",
    "pending_payment",
    "at_checkpoint",
    "checkpoint_voided",
    "pending_verification"
];
const eventReservationStatus = [
    "accepted",
    "inquiry",
    "timedout",
    "pending",
    "cancelled"
];
function isValidStatus(status) {
    return [
        "inquiry",
        "pending",
        "accepted",
        "cancelled",
        "needs_update",
        "timedout",
        "preapproved",
        "not_possible",
        "denied",
        "awaiting_payment",
        "withdrawn"
    ].includes(status);
}
const permanentlyFailedMessageStatus = [
    "review_already_left",
    "review_not_left_cohost",
    "review_failed",
    "pre-approval_dates_not_available",
    "not_us_ca_phone_number",
    "not_a_valid_phone_number",
    "phone_number_violates_blacklist_rule",
    "invalid_email",
    "email_rejected",
    "user_subscription_inactive",
    "airbnb_account_logged_out",
    "homeaway_account_logged_out",
    "booking_account_logged_out",
    "houfy_account_logged_out",
    "message_failed_to_send_within_24_hours"
];
const temporaryFailedMessageStatus = [
    "review_sent",
    "review_pending"
];
const failedMessageStatus = [
    ...permanentlyFailedMessageStatus,
    "do_not_have_permission",
    "send_message_failed",
    "sms_message_failed",
    "webhook_post_failed"
];
const successMessageStatus = [
    "sent"
];
const disabledMessageStatus = [
    "disabled"
];
const enabledMessageStatus = [
    "enabled"
];
const messageCompleteStatus = [
    ...successMessageStatus,
    ...disabledMessageStatus,
    ...permanentlyFailedMessageStatus
];
const messageStatus = [
    ...successMessageStatus,
    ...disabledMessageStatus,
    ...failedMessageStatus,
    ...enabledMessageStatus,
    ...temporaryFailedMessageStatus
];
const UTC_TIMEZONE = "Etc/UTC";
const languages = [
    "id",
    "ms",
    "ca",
    "da",
    "de",
    "en",
    "es",
    "el",
    "fr",
    "hr",
    "it",
    "hu",
    "nl",
    "no",
    "pl",
    "pt",
    "fi",
    "sv",
    "tr",
    "is",
    "cs",
    "ru",
    "he",
    "ar",
    "th",
    "ja",
    "zh",
    "zh-TW",
    "ko"
];
const secondInMs = 1000;
const minuteInMs = 60 * secondInMs;
const hourInMs = 60 * minuteInMs;
const dayInMs = 24 * hourInMs;
const yearInDays = 365;
const twoYearsInDays = 2 * yearInDays;
const apiKeyNames = [
    "OpenAI"
];
const maxImagesOnMessageRule = 5;
const maxImagesOnListing = 20;
const rateNames = [
    // PRICE
    "Base price",
    // DEPOSIT
    "Security deposit",
    // FEES
    "Cleaning fee",
    "Short term cleaning fee",
    "Security deposit",
    "Community fee",
    "Management fee",
    "Resort fee",
    "Pet fee",
    "Gas fee",
    "Electricity fee",
    "Water fee",
    "Heating fee",
    "Linens fee",
    "Air conditioning fee",
    "Utility fee",
    "Channel guest fee",
    "Channel guest VAT",
    "Channel host fee",
    "Channel host VAT",
    "Payment processing fee",
    "Additional guest fee",
    // PASS THROUGH TAXES
    "Hotel tax",
    "Lodging tax",
    "Room tax",
    "Tourist tax",
    "Transient occupancy tax",
    "Sales tax",
    "VAT/GST",
    "Tourism Assessment/Fee",
    "Total taxes",
    // COLLECTED TAXES
    "Accommodations Tax",
    "General Sales and Use Tax"
];
const rateTypes = [
    "fee",
    "tax",
    "price",
    "deposit"
];
const BETA_HOST = "https://beta.hosttools.com";
const PROD_HOST = "https://app.hosttools.com";
const helpLink = "https://help.hosttools.com";
const supportEmail = "support@hosttools.com";
const systemStatusLink = "https://hosttools.instatus.com/";
const noop = ()=>{};
