"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const useDisclose = (defaultValue = false)=>{
    const [isOpen, setOpen] = (0, _react.useState)(defaultValue);
    const onOpen = (0, _react.useCallback)(()=>{
        setOpen(true);
    }, []);
    const onClose = (0, _react.useCallback)(()=>{
        setOpen(false);
    }, []);
    const onToggle = (0, _react.useCallback)(()=>{
        setOpen((prev)=>!prev);
    }, []);
    return {
        isOpen,
        onOpen,
        onClose,
        onToggle
    };
};
const _default = useDisclose;
