"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "PaperPlaneIcon", {
    enumerable: true,
    get: function() {
        return PaperPlaneIcon;
    }
});
const _paperplanesvg = require("../../../assets/paper-plane.svg");
const _iconMaker = require("./iconMaker");
const PaperPlaneIcon = (0, _iconMaker.iconMaker)(_paperplanesvg.ReactComponent, "PaperPlaneIcon");
