"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "fonts", {
    enumerable: true,
    get: function() {
        return fonts;
    }
});
const fonts = {
    fonts: {
        heading: "Darker Grotesque",
        body: "InterDisplay",
        mono: "InterDisplay",
        paragraph: "InterDisplay"
    }
};
