"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "EnvelopeIcon", {
    enumerable: true,
    get: function() {
        return EnvelopeIcon;
    }
});
const _envelopeiconsvg = require("../../../assets/envelope-icon.svg");
const _iconMaker = require("./iconMaker");
const EnvelopeIcon = (0, _iconMaker.iconMaker)(_envelopeiconsvg.ReactComponent, "EnvelopeIcon");
