"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
class MessageService {
    async fetchMessages(reservationID, config) {
        try {
            const url = `/getMessages/${reservationID}`;
            const { data } = await this.http.get(url, config);
            return data.messages;
        } catch (e) {
            return undefined;
        }
    }
    async sendMessage({ reservationID, message, files }, config) {
        const url = "/sendMessage";
        // media part
        if (files) {
            const formData = new FormData();
            formData.append("reservationID", reservationID);
            formData.append("message", message);
            files.forEach(({ uri, file, filename })=>{
                if (uri) {
                    const name = filename !== null && filename !== void 0 ? filename : uri.split("/").pop();
                    const match = name && /\.(\w+)$/.exec(name);
                    const type = match ? `image/${match[1]}` : "image";
                    // it's confusing why it works?
                    formData.append("image", {
                        uri,
                        name,
                        type
                    });
                } else if (file) {
                    formData.append("image", file, filename);
                }
            });
            const { data } = await this.http.post(url, formData, _object_spread_props(_object_spread({}, config), {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                // this fixes to send form for 0.x version
                transformRequest: (data)=>data
            }));
            return data;
        }
        const { data } = await this.http.post(url, {
            reservationID,
            message
        }, _object_spread({}, config));
        return data;
    }
    async getMessage(reservationID, messageRuleID) {
        const url = `/getMessage/${reservationID}/${messageRuleID}`;
        const { data } = await this.http.get(url);
        return data.message;
    }
    async customizeMessage(messageRuleID, reservationID, message) {
        await this.http.post("/customizeMessage", {
            messageRuleID,
            reservationID,
            message
        });
    }
    async customizeReview(messageRuleID, reservationID, review) {
        await this.http.post("/customizeReview", {
            messageRuleID,
            reservationID,
            review
        });
    }
    async disableMessage(params) {
        await this.http.post("/disableMessage", params);
    }
    async sendMessageNow(params) {
        await this.http.post("/sendMessageNow", params);
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
const _default = MessageService;
