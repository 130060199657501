"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
function useTimeout(callback, delay) {
    const savedCallbackRef = (0, _react.useRef)(callback);
    const idRef = (0, _react.useRef)();
    (0, _react.useEffect)(()=>{
        savedCallbackRef.current = callback;
    }, [
        callback
    ]);
    (0, _react.useEffect)(()=>{
        return ()=>{
            if (typeof idRef.current === "number") {
                clearTimeout(idRef.current);
            }
        };
    }, [
        delay
    ]);
    return (0, _react.useCallback)((...args)=>{
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return;
        }
        const id = window.setTimeout(()=>savedCallbackRef.current(...args), delay);
        idRef.current = id;
    }, [
        delay
    ]);
}
const _default = useTimeout;
