"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "EyeSolidIcon", {
    enumerable: true,
    get: function() {
        return EyeSolidIcon;
    }
});
const _eyesolidiconsvg = require("../../../assets/eye-solid-icon.svg");
const _iconMaker = require("./iconMaker");
const EyeSolidIcon = (0, _iconMaker.iconMaker)(_eyesolidiconsvg.ReactComponent, "EyeSolidIcon");
