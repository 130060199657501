"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _constant = require("../../admin/theme/constant");
function useOverlayStyle(type) {
    const overlay = (0, _react.useMemo)(()=>({
            style: {
                // this helps to prevent user from scrolling from top down direction on Safari iOS
                overflow: "hidden",
                zIndex: type === "popover" ? _constant.ZINDEX_POPOVER : type === "modal" ? _constant.ZINDEX_MODAL : _constant.ZINDEX_DRAWER
            }
        }), [
        type
    ]);
    return overlay;
}
const _default = useOverlayStyle;
