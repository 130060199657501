"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useBreakpoint", {
    enumerable: true,
    get: function() {
        return useBreakpoint;
    }
});
const _react = require("react");
const _theme = require("../context/theme");
const minWidthQuery = (breakpoint)=>window.matchMedia(`(min-width: ${breakpoint}px)`);
const getCurrentBreakpoint = (tabletQuery, desktopQuery)=>{
    if (desktopQuery.matches) {
        return "desktop";
    }
    if (tabletQuery.matches) {
        return "tablet";
    }
    return "mobile";
};
function useBreakpoint() {
    const { breakpoints } = (0, _react.useContext)(_theme.Theme);
    const [state, setState] = (0, _react.useState)();
    const { tablet, desktop } = breakpoints;
    (0, _react.useEffect)(()=>{
        let mounted = true;
        const tabletQuery = minWidthQuery(tablet);
        const desktopQuery = minWidthQuery(desktop);
        const onChange = ()=>{
            if (!mounted) {
                return;
            }
            const newBreakPoint = getCurrentBreakpoint(tabletQuery, desktopQuery);
            if (newBreakPoint !== state) {
                setState(newBreakPoint);
            }
        };
        tabletQuery.addEventListener("change", onChange);
        desktopQuery.addEventListener("change", onChange);
        onChange();
        return ()=>{
            mounted = false;
            tabletQuery.removeEventListener("change", onChange);
            desktopQuery.removeEventListener("change", onChange);
        };
    }, []);
    return state;
}
