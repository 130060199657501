"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useInterval", {
    enumerable: true,
    get: function() {
        return useInterval;
    }
});
const _react = require("react");
function useInterval(callback, delay) {
    const savedCallback = (0, _react.useRef)(callback);
    (0, _react.useLayoutEffect)(()=>{
        savedCallback.current = callback;
    }, [
        callback
    ]);
    (0, _react.useEffect)(()=>{
        const id = setInterval(()=>{
            savedCallback.current();
        }, delay);
        return ()=>{
            clearInterval(id);
        };
    }, [
        delay
    ]);
}
