"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    UserConsumer: function() {
        return UserConsumer;
    },
    UserContext: function() {
        return _frontend.UserContext;
    }
});
const _frontend = require("../../../../frontend/src/index");
const UserConsumer = _frontend.UserContext.Consumer;
