"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _timefasticonsvg = require("../../../assets/time-fast-icon.svg");
const _iconMaker = require("./iconMaker");
const TimeFastIcon = (0, _iconMaker.iconMaker)(_timefasticonsvg.ReactComponent, "TimeFastIcon");
const _default = TimeFastIcon;
