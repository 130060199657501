"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _reactrouterdom = require("react-router-dom");
const useSearchQuery = ()=>{
    const { search } = (0, _reactrouterdom.useLocation)();
    return (0, _react.useMemo)(()=>new URLSearchParams(search), [
        search
    ]);
};
const _default = useSearchQuery;
