"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _dollariconsvg = require("../../../assets/dollar-icon.svg");
const _iconMaker = require("./iconMaker");
const DollarIcon = (0, _iconMaker.iconMaker)(_dollariconsvg.ReactComponent, "DollarIcon");
const _default = DollarIcon;
