"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "CheckedCircleIcon", {
    enumerable: true,
    get: function() {
        return CheckedCircleIcon;
    }
});
const _checkedcircleiconsvg = require("../../../assets/checked-circle-icon.svg");
const _iconMaker = require("./iconMaker");
const CheckedCircleIcon = (0, _iconMaker.iconMaker)(_checkedcircleiconsvg.ReactComponent, "CheckedCircleIcon");
