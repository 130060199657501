"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "DoubleChevronRightIcon", {
    enumerable: true,
    get: function() {
        return DoubleChevronRightIcon;
    }
});
const _doublechevronrightsvg = require("../../../assets/double-chevron-right.svg");
const _iconMaker = require("./iconMaker");
const DoubleChevronRightIcon = (0, _iconMaker.iconMaker)(_doublechevronrightsvg.ReactComponent, "DoubleChevronRightIcon");
