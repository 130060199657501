"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    fetchCalendarData: function() {
        return fetchCalendarData;
    },
    fetchCalendarDataByListingID: function() {
        return fetchCalendarDataByListingID;
    }
});
const _utilsreservation = require("../../../../../core/src/shared/utils/utils.reservation");
const sanitizeMultiCalendarData = (data)=>{
    return Object.keys(data).reduce((result, key)=>{
        const { prices, reservations, currency } = data[key];
        result[key] = {
            currency,
            prices,
            reservations: reservations.map((elem)=>(0, _utilsreservation.sanitize)(elem))
        };
        return result;
    }, {});
};
async function fetchCalendarData(start, end) {
    const url = `/calendars/${start}/${end}`;
    const res = await fetch(url);
    if (res.ok) {
        const data = await res.json();
        return sanitizeMultiCalendarData(data);
    }
}
async function fetchCalendarDataByListingID(listingID, start, end) {
    const url = `/getCalendar/${listingID}/${start}/${end}`;
    const res = await fetch(url);
    if (res.ok) {
        const data = await res.json();
        return sanitizeMultiCalendarData({
            [listingID]: data
        });
    }
}
