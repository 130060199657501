"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _constant = require("../../../../../core/src/constant");
const _react = require("react");
function useAccounts(accounts) {
    return (0, _react.useMemo)(()=>{
        const isLockAccount = (acc)=>{
            return _constant.integrations.includes(acc.type);
        };
        const [integrationAccounts, channelAccounts] = accounts.reduce((arr, account)=>{
            if (isLockAccount(account)) {
                arr[0].push(account);
            } else {
                arr[1].push(account);
            }
            return arr;
        }, [
            [],
            []
        ]);
        return {
            integrations: integrationAccounts.filter((account)=>account.lastLoginAttemptSuccessful),
            badIntegrations: integrationAccounts.filter((account)=>account.lastLoginAttemptSuccessful === false),
            channels: channelAccounts.filter((account)=>{
                return account.lastLoginAttemptSuccessful;
            }),
            badChannels: channelAccounts.filter((account)=>{
                return account.lastLoginAttemptSuccessful === false;
            })
        };
    }, [
        accounts
    ]);
}
const _default = useAccounts;
