"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useDraftMessage", {
    enumerable: true,
    get: function() {
        return useDraftMessage;
    }
});
const _react = require("react");
const _UserContext = require("../../contexts/UserContext");
const _useLatest = require("../useLatest");
const useDraftMessage = ({ id, setMessage, storage: localStorage })=>{
    const { user } = (0, _react.useContext)(_UserContext.UserContext);
    const setMessageRef = (0, _useLatest.useLatest)(setMessage);
    const getDraftMessageId = (0, _react.useCallback)(()=>{
        return `draftMessage_${id}_${user === null || user === void 0 ? void 0 : user._id}`;
    }, [
        id,
        user === null || user === void 0 ? void 0 : user._id
    ]);
    const saveDraftMessage = (0, _react.useCallback)((message)=>{
        if (message) {
            localStorage.setItem(getDraftMessageId(), message);
        } else {
            localStorage.removeItem(getDraftMessageId());
        }
    }, [
        getDraftMessageId,
        localStorage
    ]);
    const clearDraft = (0, _react.useCallback)(()=>{
        localStorage.removeItem(getDraftMessageId());
    }, [
        getDraftMessageId,
        localStorage
    ]);
    (0, _react.useEffect)(()=>{
        const draftMessagePromise = localStorage.getItem(getDraftMessageId());
        if (draftMessagePromise instanceof Promise) {
            (async ()=>{
                const draftMessage = await draftMessagePromise;
                setMessageRef.current(draftMessage);
            })();
            return;
        }
        setMessageRef.current(draftMessagePromise);
    }, [
        getDraftMessageId,
        localStorage,
        setMessageRef
    ]);
    return (0, _react.useMemo)(()=>({
            saveDraftMessage,
            clearDraft
        }), [
        saveDraftMessage,
        clearDraft
    ]);
};
