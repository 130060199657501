"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "parseFailuresIntoArray", {
    enumerable: true,
    get: function() {
        return parseFailuresIntoArray;
    }
});
function parseFailuresIntoArray(failures) {
    if (!failures) {
        return failures;
    }
    const result = Object.keys(failures).reduce((map, key)=>{
        const obj = failures[key];
        let error = "";
        if (typeof obj === "string") {
            error = obj;
        }
        if (typeof obj === "object" && obj.error) {
            error = obj.error;
        }
        if (error) {
            map.set(key, error !== null && error !== void 0 ? error : "");
        }
        return map;
    }, new Map());
    return [
        ...result
    ];
}
