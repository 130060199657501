"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _constant = require("../../../../core/src/constant");
const _react = require("react");
const defaultValue = {
    toast: {
        show: _constant.noop,
        close: _constant.noop,
        closeAll: _constant.noop,
        success: _constant.noop,
        info: _constant.noop,
        warning: _constant.noop,
        error: _constant.noop
    },
    setToast: _constant.noop
};
const ToastContext = (0, _react.createContext)(defaultValue);
const _default = ToastContext;
