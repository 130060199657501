"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    isListingCell: function() {
        return isListingCell;
    },
    isListingType: function() {
        return isListingType;
    },
    isLockCell: function() {
        return isLockCell;
    }
});
const isListingType = (model)=>{
    return "listingEnabled" in model;
};
const isListingCell = (props)=>isListingType(props.row.original);
const isLockCell = (props)=>!isListingType(props.row.original);
