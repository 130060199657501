"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    UserContext: function() {
        return UserContext;
    },
    defaultValue: function() {
        return defaultValue;
    }
});
const _constant = require("../../../../core/src/constant");
const _react = require("react");
const _user = require("../../models/user");
const defaultValue = {
    isLoading: true,
    isAuthenticated: false,
    isLoadingAccounts: false,
    user: new _user.User({
        _id: "",
        firstName: "",
        lastName: "",
        username: "",
        subscriptionStatus: "",
        isBeta: false,
        intercomHash: "",
        trialLengthEndDate: undefined,
        createdAt: ""
    }),
    seamToken: "",
    accounts: [],
    listings: [],
    visibleListings: [],
    listingGroups: [],
    locks: [],
    // if tags is undefined, it means it's still loading
    // it means we have to handle the loading state in the component
    tags: undefined,
    permissions: {},
    errors: {},
    logout: ()=>Promise.resolve(),
    setUser: _constant.noop,
    setState: _constant.noop,
    updateUser: ()=>Promise.resolve()
};
const UserContext = (0, _react.createContext)(defaultValue);
