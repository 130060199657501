"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useWatchNotification", {
    enumerable: true,
    get: function() {
        return useWatchNotification;
    }
});
const _react = require("react");
const useWatchNotification = (ws)=>{
    const [notification, setNotification] = (0, _react.useState)(null);
    (0, _react.useEffect)(()=>{
        function generateNewNotification(payload) {
            setNotification(payload);
        }
        ws === null || ws === void 0 ? void 0 : ws.on("notification", generateNewNotification);
        return ()=>{
            ws === null || ws === void 0 ? void 0 : ws.off("notification", generateNewNotification);
        };
    }, [
        ws
    ]);
    return notification;
};
