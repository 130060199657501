"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "TableContext", {
    enumerable: true,
    get: function() {
        return TableContext;
    }
});
const _react = require("react");
const TableContext = (0, _react.createContext)({});
