"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "mapListingReservation", {
    enumerable: true,
    get: function() {
        return mapListingReservation;
    }
});
function mapListingReservation(reservations, listings) {
    // make map with an given 2D array
    const listingsInObject = new Map([
        ...listings.map((elem)=>[
                elem._id,
                elem
            ])
    ]);
    const results = reservations.reduce((result, reservation)=>{
        const listing = listingsInObject.get(reservation.listingID);
        if (listing) {
            result = [
                ...result,
                {
                    reservation,
                    listing
                }
            ];
        }
        return result;
    }, []);
    return results;
}
