"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getLocal: function() {
        return getLocal;
    },
    setLocal: function() {
        return setLocal;
    }
});
function setLocal(key, value) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
    // Do Nothing
    }
}
function getLocal(key) {
    let value = null;
    try {
        value = window.localStorage.getItem(key);
        value = JSON.parse(value);
    } catch (e) {
    // Do Nothing
    }
    return value;
}
