"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _trashsvg = require("../../../assets/trash.svg");
const _iconMaker = require("./iconMaker");
const TrashIcon = (0, _iconMaker.iconMaker)(_trashsvg.ReactComponent, "TrashIcon");
const _default = TrashIcon;
