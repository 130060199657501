"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useAccount", {
    enumerable: true,
    get: function() {
        return useAccount;
    }
});
const _react = require("react");
const _contexts = require("../../contexts");
function useAccount(accountID) {
    const { accounts } = (0, _react.useContext)(_contexts.UserContext);
    return (0, _react.useMemo)(()=>{
        if (!accountID) {
            return;
        }
        return accounts.find((elem)=>elem._id === accountID);
    }, [
        accountID,
        accounts
    ]);
}
