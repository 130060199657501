"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _parseFailuresIntoArray = require("../../../../../../frontend/src/utils/parseFailuresIntoArray");
const _react = require("react");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
const useAccountErrors = (account)=>{
    return (0, _react.useMemo)(()=>(0, _parseFailuresIntoArray.parseFailuresIntoArray)(_object_spread({}, account.failures, !account.lastLoginAttemptSuccessful && {
            disconnected: {
                error: "This channel is not connected"
            }
        })), [
        account.failures,
        account.lastLoginAttemptSuccessful
    ]);
};
const _default = useAccountErrors;
