"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _homesvg = require("../../../assets/home.svg");
const _iconMaker = require("./iconMaker");
const HomeIcon = (0, _iconMaker.iconMaker)(_homesvg.ReactComponent, "HomeIcon");
const _default = HomeIcon;
