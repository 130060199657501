"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "Badge", {
    enumerable: true,
    get: function() {
        return Badge;
    }
});
const _nativebase = require("native-base");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const Badge = {
    variants: _object_spread_props(_object_spread({}, _nativebase.theme.components.Badge.variants), {
        solid: (props)=>{
            const { colorScheme } = props;
            return {
                _text: {
                    color: "text.50"
                },
                _icon: {
                    color: "text.50",
                    size: "xs"
                },
                bg: colorScheme === "amber" ? "amber.500" : `${colorScheme}.600`,
                borderWidth: "1",
                borderColor: "transparent",
                borderRadius: "4px"
            };
        },
        subtle: (props)=>{
            const { colorScheme } = props;
            return {
                _text: {
                    color: `${colorScheme}.600`
                },
                _icon: {
                    color: `${colorScheme}.600`,
                    size: "xs"
                },
                bg: `${colorScheme}.50`,
                _dark: {
                    bg: `${colorScheme}.300`
                },
                borderWidth: "1",
                borderRadius: "4px",
                borderColor: `${colorScheme}.200`
            };
        },
        outline: (props)=>{
            const { colorScheme } = props;
            return {
                _text: {
                    color: `${colorScheme}.600`
                },
                _icon: {
                    color: `${colorScheme}.600`,
                    size: "xs"
                },
                borderColor: `${colorScheme}.200`,
                _dark: {
                    _text: {
                        color: `${colorScheme}.300`
                    },
                    _icon: {
                        color: `${colorScheme}.300`
                    },
                    borderColor: `${colorScheme}.300`
                },
                borderRadius: "4px",
                borderWidth: "1"
            };
        }
    }),
    baseStyle: _object_spread_props(_object_spread({}, _nativebase.theme.components.Badge.baseStyle), {
        px: "1",
        py: "0.5",
        alignItems: "center",
        _text: {
            fontSize: "xs",
            fontWeight: "medium"
        }
    }),
    defaultProps: {
        size: "md",
        variant: "subtle",
        colorScheme: "blueGray"
    }
};
