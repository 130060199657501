"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useUserContext", {
    enumerable: true,
    get: function() {
        return useUserContext;
    }
});
const _react = require("react");
const _contexts = require("../../contexts");
function useUserContext() {
    return (0, _react.useContext)(_contexts.UserContext);
}
