"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "Theme", {
    enumerable: true,
    get: function() {
        return Theme;
    }
});
const _react = require("react");
const tablet = 768;
const desktop = 1024;
const Theme = (0, _react.createContext)({
    breakpoints: {
        tablet,
        desktop
    }
});
