"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "DisabledIcon", {
    enumerable: true,
    get: function() {
        return DisabledIcon;
    }
});
const _disablediconsvg = require("../../../assets/disabled-icon.svg");
const _iconMaker = require("./iconMaker");
const DisabledIcon = (0, _iconMaker.iconMaker)(_disablediconsvg.ReactComponent, "DisabledIcon");
