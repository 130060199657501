"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return useIsMacDevice;
    }
});
const _react = require("react");
const _utils = require("../../admin/utils");
function useIsMacDevice() {
    return (0, _react.useMemo)(()=>(0, _utils.checkIsMacDevice)(), []);
}
