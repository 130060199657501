"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _http = require("../../http");
const http = (0, _http.createHttp)("/");
const HTTPContext = (0, _react.createContext)(http);
const _default = HTTPContext;
