"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "fetchMessageRules", {
    enumerable: true,
    get: function() {
        return fetchMessageRules;
    }
});
async function fetchMessageRules(payload) {
    const listingID = payload === null || payload === void 0 ? void 0 : payload.listingID;
    const listingGroupID = payload === null || payload === void 0 ? void 0 : payload.listingGroupID;
    let url = "/getMessageRules";
    if (listingGroupID) {
        url += `/group/${listingGroupID}`;
    } else if (listingID) {
        url += `/${listingID}`;
    }
    const res = await fetch(url);
    if (res.ok) {
        const data = await res.json();
        return data;
    }
}
