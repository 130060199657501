"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _channel = require("../../../models/channel");
function useChannel(channel, isChannelConnector) {
    return (0, _react.useMemo)(()=>(0, _channel.buildChannel)(channel, isChannelConnector), [
        channel,
        isChannelConnector
    ]);
}
const _default = useChannel;
