"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    REGEX_URL: function() {
        return REGEX_URL;
    },
    default: function() {
        return _default;
    }
});
const REGEX_URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/i;
function validateUrl(value) {
    return REGEX_URL.test(value);
}
const _default = validateUrl;
