"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "DoubleChevronLeftIcon", {
    enumerable: true,
    get: function() {
        return DoubleChevronLeftIcon;
    }
});
const _doublechevronleftsvg = require("../../../assets/double-chevron-left.svg");
const _iconMaker = require("./iconMaker");
const DoubleChevronLeftIcon = (0, _iconMaker.iconMaker)(_doublechevronleftsvg.ReactComponent, "DoubleChevronLeftIcon");
