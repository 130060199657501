"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getListingGroupUrl", {
    enumerable: true,
    get: function() {
        return getListingGroupUrl;
    }
});
function getListingGroupUrl(id) {
    return `/messaging/group/${id}`;
}
