"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    fetchReservation: function() {
        return fetchReservation;
    },
    updatePosts: function() {
        return updatePosts;
    }
});
async function fetchReservation(reservationID) {
    const url = `/reservation/${reservationID}`;
    const res = await fetch(url);
    if (res.ok) {
        const data = await res.json();
        return data;
    }
}
async function updatePosts(reservationID) {
    const url = `/updatePosts/${reservationID}`;
    const res = await fetch(url);
    return res;
}
