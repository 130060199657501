"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _channel = require("../model/channel");
const getChannelLabel = (channel)=>{
    return channel === _channel.Channel.HomeAway ? "VRBO" : channel;
};
const _default = getChannelLabel;
