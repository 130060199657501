"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    downloadFileBlob: function() {
        return downloadFileBlob;
    },
    downloadRawFile: function() {
        return downloadRawFile;
    },
    generateImageUploadFormData: function() {
        return generateImageUploadFormData;
    }
});
function generateImageUploadFormData({ uri, file, filename }) {
    const formData = new FormData();
    // media part
    if (uri) {
        const name = filename !== null && filename !== void 0 ? filename : uri.split("/").pop();
        const match = name && /\.(\w+)$/.exec(name);
        const type = match ? `image/${match[1]}` : "image";
        // it's confusing why it works?
        formData.append("image", {
            uri,
            name,
            type
        });
    } else if (file) {
        formData.append("image", file, filename);
    }
    return formData;
}
function downloadFileBlob(blob, name) {
    const url = window.URL.createObjectURL(blob);
    downloadRawFile(url, name);
}
function downloadRawFile(rawFile, fileName) {
    const a = document.createElement("a");
    a.href = rawFile;
    if (fileName) {
        a.download = fileName;
    }
    document.body.appendChild(a);
    a.click();
}
