"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _solidchevronupsvg = require("../../../assets/solid-chevron-up.svg");
const _iconMaker = require("./iconMaker");
const SolidChevronUpIcon = (0, _iconMaker.iconMaker)(_solidchevronupsvg.ReactComponent, "SolidChevronUpIcon");
const _default = SolidChevronUpIcon;
