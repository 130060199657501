"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _frontend = require("../../../../../../frontend/src/index");
const _react = require("react");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const useBillingListings = (billing)=>{
    const { listings } = (0, _react.useContext)(_frontend.UserContext);
    const data = (0, _react.useMemo)(()=>{
        return listings.map((listing)=>{
            const isPro = listing.pricingPlan === "Pro";
            const price = billing.prices.find((elem)=>(isPro ? elem.name === "Pro Plan" : elem.name === "Messaging Plan") && elem.frequency === billing.frequency);
            return _object_spread_props(_object_spread({}, listing), {
                price: price === null || price === void 0 ? void 0 : price.formattedPricePerListing,
                frequency: price === null || price === void 0 ? void 0 : price.frequency
            });
        });
    }, [
        billing,
        listings
    ]);
    return data;
};
const _default = useBillingListings;
