"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useProFeatures", {
    enumerable: true,
    get: function() {
        return useProFeatures;
    }
});
const _utilslisting = require("../../../../../core/src/shared/utils/utils.listing");
const _react = require("react");
const _useUserContext = require("../useUserContext");
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function useProFeatures(listing) {
    const { locks, listingGroups } = (0, _useUserContext.useUserContext)();
    const _useMemo = (0, _react.useMemo)(()=>(0, _utilslisting.isProPlan)(listing, locks, listingGroups.filter((elem)=>elem.accessCode)), [
        listing,
        locks,
        listingGroups
    ]), { isPro } = _useMemo, rest = _object_without_properties(_useMemo, [
        "isPro"
    ]);
    const features = (0, _react.useMemo)(()=>Object.keys(rest).filter((elem)=>rest[elem]).reduce((acc, key)=>{
            if (key === "isTurnover") {
                acc.push("Turnover Calendar");
            }
            if (key === "isPricing") {
                acc.push("Calendar Syncing");
            }
            if (key === "hasLock") {
                acc.push("Smart Locks");
            }
            return acc;
        }, []), [
        rest
    ]);
    return {
        isPro,
        label: isPro ? "PRO" : "Messaging",
        features: isPro ? `Pro features: ${features.join(", ")}` : undefined
    };
}
