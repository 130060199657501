"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function calculateDimension(width, height, aspectRatio) {
    if (width && height) {
        return {
            width,
            height
        };
    }
    const [wRatio, hRatio] = (aspectRatio !== null && aspectRatio !== void 0 ? aspectRatio : "5:4").split(":").map(Number);
    if (width) {
        height = typeof width === "number" ? width / wRatio * hRatio : width.map((w)=>w / wRatio * hRatio);
    } else if (height) {
        width = typeof height === "number" ? height / hRatio * wRatio : height.map((h)=>h / hRatio * wRatio);
    }
    return {
        width,
        height
    };
}
const _default = calculateDimension;
