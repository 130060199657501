"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _channel = require("../../../../frontend/src/models/channel");
const _react = require("@seamapi/react");
const _react1 = require("react");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const useSeamAccountLogo = (accounts)=>{
    const { deviceProviders } = (0, _react.useDeviceProviders)();
    return (0, _react1.useMemo)(()=>{
        if (!deviceProviders) {
            return accounts;
        }
        return accounts.map((elem)=>{
            var _elem_locks_;
            if (elem.type === "Seam" && ((_elem_locks_ = elem.locks[0]) === null || _elem_locks_ === void 0 ? void 0 : _elem_locks_.manufacturer)) {
                var _elem_locks_1;
                const manufacturer = (_elem_locks_1 = elem.locks[0]) === null || _elem_locks_1 === void 0 ? void 0 : _elem_locks_1.manufacturer;
                const provider = deviceProviders.find((provider)=>provider.device_provider_name === manufacturer);
                if (provider) {
                    return _object_spread_props(_object_spread({}, elem), {
                        logo: provider === null || provider === void 0 ? void 0 : provider.image_url,
                        locks: elem.locks.map((lock)=>{
                            var _provider_display_name;
                            return _object_spread_props(_object_spread({}, lock), {
                                logo: provider.image_url,
                                manufacturer: (_provider_display_name = provider.display_name) !== null && _provider_display_name !== void 0 ? _provider_display_name : ""
                            });
                        })
                    });
                }
            }
            const { logo } = (0, _channel.buildChannel)(elem.type);
            return _object_spread_props(_object_spread({}, elem), {
                logo
            });
        });
    }, [
        accounts,
        deviceProviders
    ]);
};
const _default = useSeamAccountLogo;
