"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _default = {
    primary: {
        50: "#eff6ff",
        100: "#dbeafe",
        200: "#bfdbfe",
        300: "#93c5fd",
        400: "#60a5fa",
        500: "#3b82f6",
        600: "#2563eb",
        700: "#1d4ed8",
        800: "#1e40af",
        900: "#1e3a8a"
    },
    Airbnb: {
        50: "#FFDFE5",
        100: "#FEF2F2",
        200: "#FF385C",
        300: "#FF385C",
        400: "#FF385C",
        500: "#FF385C",
        600: "#FF385C",
        700: "#FF385C",
        800: "#FF385C",
        900: "#FF385C"
    },
    Booking: {
        50: "#DAE9FF",
        100: "#EFF1FE",
        200: "#003B95",
        300: "#003B95",
        400: "#003B95",
        500: "#003B95",
        600: "#003B95",
        700: "#003B95",
        800: "#003B95",
        900: "#003B95"
    },
    Houfy: {
        50: "#D3E2EC",
        100: "#F6E9FF",
        200: "#AD68DD",
        300: "#AD68DD",
        400: "#AD68DD",
        500: "#153D59",
        600: "#153D59",
        700: "#153D59",
        800: "#153D59",
        900: "#153D59"
    },
    HomeAway: {
        50: "#E4EEFF",
        100: "#F2F2F2",
        200: "#000000",
        300: "#000000",
        400: "#000000",
        500: "#245ABC",
        600: "#245ABC",
        700: "#245ABC",
        800: "#245ABC",
        900: "#245ABC"
    },
    internal: {
        50: "#E0FFFD",
        100: "#F0FDF4",
        200: "#36BDB6",
        300: "#36BDB6",
        400: "#36BDB6",
        500: "#18827C",
        600: "#18827C",
        700: "#18827C",
        800: "#18827C",
        900: "#18827C"
    },
    icalendar: {
        50: "#DBEAFE",
        100: "#FFF7ED",
        200: "#FDBA74",
        300: "#EA580C",
        400: "#EA580C",
        500: "#2563EB",
        600: "#2563EB",
        700: "#2563EB",
        800: "#2563EB",
        900: "#2563EB"
    }
};
