"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "QuickReply", {
    enumerable: true,
    get: function() {
        return QuickReply;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class QuickReply {
    constructor(raw){
        _define_property(this, "_id", void 0);
        _define_property(this, "name", void 0);
        _define_property(this, "message", void 0);
        this._id = raw._id;
        var _raw_name;
        this.name = (_raw_name = raw.name) !== null && _raw_name !== void 0 ? _raw_name : "";
        var _raw_text;
        this.message = (_raw_text = raw.text) !== null && _raw_text !== void 0 ? _raw_text : "";
    }
}
