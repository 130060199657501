"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _creditcardiconsvg = require("../../../assets/credit-card-icon.svg");
const _iconMaker = require("./iconMaker");
const CreditCardIcon = (0, _iconMaker.iconMaker)(_creditcardiconsvg.ReactComponent, "CreditCardIcon");
const _default = CreditCardIcon;
