"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "IconButtonTheme", {
    enumerable: true,
    get: function() {
        return IconButtonTheme;
    }
});
const _nativebase = require("native-base");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function variantGhost({ colorScheme, disabled }) {
    return {
        borderWidth: "1px",
        borderColor: "transparent",
        bg: disabled ? `${colorScheme}.200` : "transparent",
        _icon: {
            color: disabled ? `${colorScheme}.400` : `${colorScheme}.600`
        },
        _hover: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.200`
        },
        _pressed: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.300`
        },
        _focus: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.300`
        },
        _disabled: {
            bg: `${colorScheme}.200`,
            _icon: {
                color: `${colorScheme}.400`
            }
        },
        _dark: {
            _icon: {
                color: `${colorScheme}.500`
            },
            _hover: {
                bg: `${colorScheme}.500:alpha.10`
            },
            _pressed: {
                bg: `${colorScheme}.500:alpha.20`
            }
        }
    };
}
function variantOutline({ colorScheme }) {
    return {
        borderWidth: "1px",
        borderColor: `${colorScheme}.200`,
        _icon: {
            color: `${colorScheme}.400`
        },
        _hover: {
            bg: `${colorScheme}.100`,
            _icon: {
                color: `${colorScheme}.400`
            }
        },
        _pressed: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.300`,
            _icon: {
                color: `${colorScheme}.400`
            }
        },
        _focus: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.300`,
            _icon: {
                color: `${colorScheme}.400`
            }
        },
        _dark: {
            borderColor: `${colorScheme}.500`,
            _icon: {
                color: `${colorScheme}.500`
            },
            _hover: {
                bg: `${colorScheme}.400`,
                _icon: {
                    color: "muted.900"
                }
            },
            _pressed: {
                bg: `${colorScheme}.300`,
                _icon: {
                    color: "muted.900"
                }
            },
            _focus: {
                bg: `${colorScheme}.500`,
                _icon: {
                    color: "muted.900"
                }
            }
        }
    };
}
function variantSubtle({ colorScheme }) {
    return {
        _text: {
            color: `${colorScheme}.900`
        },
        _icon: {
            color: colorScheme === "blueGray" ? "blueGray.400" : `${colorScheme}.900`
        },
        bg: `${colorScheme}.100`,
        _hover: {
            bg: `${colorScheme}.200`
        },
        _pressed: {
            bg: `${colorScheme}.300`
        },
        _dark: {
            bg: `${colorScheme}.300`,
            _hover: {
                bg: `${colorScheme}.200`
            },
            _pressed: {
                bg: `${colorScheme}.100`
            }
        },
        borderWidth: "1px",
        borderColor: `${colorScheme}.200`,
        borderStyle: "solid"
    };
}
function variantSolid({ colorScheme }) {
    return {
        bg: colorScheme === "blueGray" ? "blueGray.200" : `${colorScheme}.600`,
        _hover: {
            bg: colorScheme === "blueGray" ? "blueGray.300" : `${colorScheme}.700`
        },
        _pressed: {
            bg: colorScheme === "blueGray" ? "blueGray.300" : `${colorScheme}.800`
        },
        _icon: {
            color: colorScheme === "blueGray" ? "blueGray.400" : "muted.50"
        },
        _dark: {
            bg: `${colorScheme}.500`,
            _hover: {
                bg: `${colorScheme}.400`
            },
            _pressed: {
                bg: `${colorScheme}.300`,
                _icon: {
                    color: "muted.900"
                }
            },
            _icon: {
                color: "muted.900"
            }
        }
    };
}
const IconButtonTheme = {
    baseStyle: (props)=>{
        const baseStyleProps = _nativebase.theme.components.IconButton.baseStyle(props);
        return _object_spread({}, baseStyleProps);
    },
    variants: _object_spread_props(_object_spread({}, _nativebase.theme.components.IconButton.variants), {
        solid: variantSolid,
        ghost: variantGhost,
        outline: variantOutline,
        subtle: variantSubtle
    }),
    sizes: {
        lg: {
            p: "1",
            _icon: {
                size: "md"
            }
        },
        md: {
            p: "1",
            _icon: {
                size: "sm"
            }
        },
        sm: {
            p: "1",
            _icon: {
                size: "xs"
            }
        },
        xs: {
            p: "1",
            _icon: {
                size: "xs"
            }
        }
    },
    defaultProps: {
        colorScheme: "blueGray",
        variant: "ghost",
        size: "md"
    }
};
