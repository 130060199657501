"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    AirbnbLogo: function() {
        return AirbnbLogo;
    },
    BookingLogo: function() {
        return BookingLogo;
    },
    HoufyLogo: function() {
        return HoufyLogo;
    },
    VRBOLogo: function() {
        return VRBOLogo;
    }
});
const _reacticons = require("react-icons");
// How to make custom icons:
// https://react-icons-json-generator.surge.sh/
// https://github.com/react-icons/react-icons/issues/238
// https://github.com/matthova/react-icons-converter
const airbnbJson = {
    tag: "svg",
    attr: {
        enableBackground: "new 0 0 900 900",
        viewBox: "0 0 900 900"
    },
    child: [
        {
            tag: "path",
            attr: {
                d: "m836.1 630.2c-4.3-10.3-8.6-21.4-12.8-30.8-6.9-15.4-13.7-30-19.7-43.7l-.9-.9c-59-128.4-122.4-258.5-189.1-386.9l-2.6-5.1c-7-13.3-13.9-26.7-20.5-40.2-8.6-15.4-17.1-31.7-30.8-47.1-27.4-34.2-66.8-53.1-108.7-53.1-42.8 0-81.3 18.8-109.6 51.4-12.9 15.4-22.3 31.7-30.8 47.1-6.7 13.5-13.5 26.9-20.5 40.2l-2.6 5.2c-66 128.3-130.2 258.5-189.3 386.9l-.9 1.7c-6 13.7-12.8 28.3-19.7 43.7-4.3 9.4-8.6 19.7-12.8 30.8-11.2 31.6-14.6 61.6-10.3 92.4 9.3 63.7 51.7 117.8 111.3 142.1 21.9 9.2 45.5 13.9 69.3 13.7 7.4-.1 14.9-.7 22.3-1.7 28.3-3.4 57.4-12.8 85.6-29.1 35.1-19.7 68.5-47.9 106.1-89 37.7 41.1 71.9 69.3 106.1 89 28.3 16.3 57.4 25.7 85.6 29.1 7.4 1 14.8 1.6 22.3 1.7 24 0 47.9-4.3 69.3-13.7 59.9-24 101.9-78.7 111.3-142.1 6.9-30 3.5-59.9-7.6-91.6zm-386.1 44.6c-46.2-58.2-76.2-113-86.5-159.2-4.3-19.7-5.1-36.8-2.6-52.2 1.7-12.9 6.4-25.2 13.7-36 16.2-23.1 43.6-37.7 75.3-37.7s59.9 13.7 75.3 37.7c7.3 10.8 12 23.1 13.7 36 2.6 15.4 1.7 33.4-2.6 52.2-10.1 45.3-40.1 100.1-86.3 159.2zm341.6 40.2c-6 44.5-36 83.1-77.8 100.2-20.5 8.6-42.8 11.1-65.1 8.6-21.4-2.6-42.8-9.4-65.1-22.2-30.8-17.1-61.6-43.7-97.6-83 56.5-69.3 90.7-132.7 103.6-189.2 5.6-23.8 7-48.4 4.3-72.8-3.2-20.9-11.1-40.8-23.1-58.2-26.6-38.5-71.1-60.8-120.7-60.8s-94.2 23.1-120.7 60.8c-12 17.4-19.9 37.3-23.1 58.2-3.6 24.3-2.2 49.1 4.3 72.8 12.8 56.5 47.9 120.7 103.6 190-35.1 39.4-66.8 65.9-97.6 83.1-22.3 12.8-43.6 19.7-65 22.2-22.1 2.5-44.4-.5-65.1-8.6-41.9-17.1-71.9-55.7-77.8-100.2-2.6-21.4-.9-42.8 7.7-66.8 2.6-8.6 6.9-17.1 11.1-27.4 6-13.7 12.8-28.3 19.7-42.8l.9-1.7c59-127.6 122.3-257.7 188.2-384.4l2.6-5.2c6.9-12.8 13.7-26.5 20.6-39.4 6.8-13.7 14.5-26.5 24-37.7 18-20.5 41.9-31.7 68.5-31.7s50.5 11.1 68.5 31.7c9.4 11.2 17.1 24 24 37.7 6.9 12.9 13.8 26.5 20.6 39.4l2.6 5.2c64.5 127.2 127 255.6 187.1 385.2v.9c6.9 13.7 12.8 29.1 19.7 42.8 4.3 10.3 8.6 18.8 11.1 27.4 6.8 22.2 9.4 43.6 6 65.9z"
            },
            child: []
        }
    ]
};
const AirbnbLogo = (props)=>(0, _reacticons.GenIcon)(airbnbJson)(props);
const vrboJson = {
    tag: "svg",
    attr: {
        version: "1.1",
        id: "Layer_1",
        x: "0px",
        y: "0px",
        viewBox: "0 0 80.6 74.4",
        style: "enable-background:new 0 0 80.6 74.4;"
    },
    child: [
        {
            tag: "g",
            attr: {},
            child: [
                {
                    tag: "g",
                    attr: {
                        id: "Stripes_6_"
                    },
                    child: [
                        {
                            tag: "g",
                            attr: {
                                id: "V_6_"
                            },
                            child: [
                                {
                                    tag: "g",
                                    attr: {
                                        id: "Stem_11_"
                                    },
                                    child: [
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M40.8,53.6l2-4.1l-6.7-34.1C34.2,5.8,36.4,1.6,44,0.2V0h-7c-2,1-6.3,4-3.9,15.4L40.8,53.6z"
                                            },
                                            child: []
                                        },
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M36.8,61.9l2-4.1L30,15.2c-1.6-8.2-0.9-12.5,1.8-15V0h-4.7c-1.5,3.1-1.9,6.2-0.3,14.3L36.8,61.9z"
                                            },
                                            child: []
                                        },
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M15,0.1h-5v0.2c2.2,2.7,2.8,5.9,4.6,13.1l13.2,61h2.9L17.6,13.2C15.8,5.2,15.5,3.1,15,0.1z"
                                            },
                                            child: []
                                        },
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M32.9,70.1l2-4l-11-51.8C22,6.2,22.4,2.6,23.2,0H19c-0.1,3.3,0.1,6,1.6,12.9L32.9,70.1z"
                                            },
                                            child: []
                                        },
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M5.2,0.1H0v0.2c4.8,1.9,7.2,5.6,9.1,14.1c0,0.1,13,60,13,60H25L11.9,13.9C9.6,3.4,7.2,1.5,5.2,0.1z"
                                            },
                                            child: []
                                        }
                                    ]
                                },
                                {
                                    tag: "g",
                                    attr: {
                                        id: "Finial_6_"
                                    },
                                    child: [
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M54.3,26.7l0.2,0.2C61.9,9.5,63.8,4.7,66.8,0.2V0h-5.9v0.2C61.4,7.1,59,13.5,54.3,26.7z"
                                            },
                                            child: []
                                        },
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M72.4,0.1c-6.2,3.6-9.9,10.4-17.7,27l0.2,0.2C63.8,10.6,69,3.9,80.6,0.3V0.1H72.4z"
                                            },
                                            child: []
                                        },
                                        {
                                            tag: "path",
                                            attr: {
                                                d: "M53.9,26.4l0.2,0.2c4.6-15.5,6.2-22.4,2.1-26.5h-5.8v0.2C56.8,2.5,58.2,9.1,53.9,26.4z"
                                            },
                                            child: []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
const VRBOLogo = (props)=>(0, _reacticons.GenIcon)(vrboJson)(props);
const houfyJson = {
    tag: "svg",
    attr: {
        enableBackground: "new 0 0 80.6 74.4",
        viewBox: "0 0 80.6 74.4"
    },
    child: [
        {
            tag: "path",
            attr: {
                d: "m40.3.8-38.3 27.8 14.6 45h47.4l14.6-45zm19.1 23.5c0 2.3-1.4 8.6-2.8 13-.9 2.5-1.4 3-4.8 4.8l-3.8 1.9h2.7c1.4.1 2.6.2 2.6.6 0 1.6-2.4 3.3-7 4.9l-5.2 1.7 3.4.3 3.4.3-3.5 1.7c-2.7 1.4-5 1.9-9.9 2.4-6.2.6-6.6.7-8.6 2.7-2.4 2.4-4.1 2.8-4.8 1.2-.2-.7 2.6-4 10-11.4 5.7-5.7 10.3-10.7 10.3-11.1 0-2-2.7-.2-9 6l-6.7 6.7.3-4.2c.5-5.7 2.4-9.8 6.3-13.4 5-4.7 14.4-8.6 23.6-9.8l3.5-.4z"
            },
            child: []
        }
    ]
};
const HoufyLogo = (props)=>(0, _reacticons.GenIcon)(houfyJson)(props);
const bookingJson = {
    tag: "svg",
    attr: {
        version: "1.1",
        id: "Layer_1",
        x: "0px",
        y: "0px",
        viewBox: "0 0 900 900",
        style: "enable-background:new 0 0 900 900;"
    },
    child: [
        {
            tag: "g",
            attr: {},
            child: [
                {
                    tag: "path",
                    attr: {
                        d: "M411.6,473.8h-46.4c-14.9,2.1-21.6,11.1-21.6,28.5v81.2l67.9,0.1h0c33.6,0,54.4-20.5,54.4-55.2\n\t\tC466,494.7,444.7,473.8,411.6,473.8z"
                    },
                    child: []
                },
                {
                    tag: "path",
                    attr: {
                        d: "M451.4,362c0-29.9-17.9-47.7-47.7-47.7h-34.8c-17.4,1.2-25.3,10-25.3,28.8v21.4v46.9h61.1\n\t\tC439.1,411.3,451.4,384.8,451.4,362z"
                    },
                    child: []
                },
                {
                    tag: "path",
                    attr: {
                        d: "M871.8,195.9c0-87.4-71-158.4-158.4-158.4H186.7c-87.4,0-158.4,71-158.4,158.4v508.2c0,0.8,0,1.5,0,2.3\n\t\tl0,156.1h158.4c0,0,0,0,0,0h526.7c87.4,0,158.4-71,158.4-158.4L871.8,195.9z M406,651.9h-140V290.2c0.2-22.8,18.4-41.2,41.1-42.1\n\t\th9.9v0h87.3c76.5,0,125.9,41.1,125.9,104.9c0,41.6-20.9,65.7-33.4,76.5l-10.7,9.2l12.3,6.9c29.8,16.8,47.5,49.8,47.5,88.4\n\t\tC545.9,605.6,491,651.9,406,651.9z M632.1,651.9c-27.5,0-49.7-22.4-49.7-50h0c0-27.6,22.3-50,49.7-50c27.5,0,49.9,22.4,49.9,50\n\t\tC682,629.5,659.6,651.9,632.1,651.9z"
                    },
                    child: []
                }
            ]
        }
    ]
};
const BookingLogo = (props)=>(0, _reacticons.GenIcon)(bookingJson)(props);
