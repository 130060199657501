"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    generateTimeFormatted: function() {
        return generateTimeFormatted;
    },
    getFormattedEvent: function() {
        return getFormattedEvent;
    },
    getLanguageText: function() {
        return getLanguageText;
    },
    getLimitDaysFormatted: function() {
        return getLimitDaysFormatted;
    }
});
function generateTimeFormatted() {
    const options = {};
    let i = 0;
    while(i < 24){
        let period = "am";
        if (i > 11) {
            period = "pm";
        }
        let timeNumber = i;
        if (i === 0) {
            timeNumber = 12;
        } else if (i > 12) {
            timeNumber = i - 12;
        }
        options[i] = `${timeNumber} ${period}`;
        i += 1;
    }
    return options;
}
function getFormattedEvent(event) {
    let formattedEvent = event;
    if (event === "timedout") {
        formattedEvent = "Expired Pre-Approval";
    } else if (event === "pending") {
        formattedEvent = "Booking Request";
    } else if (event === "booking") {
        formattedEvent = "Confirmed Booking";
    } else if (event === "checkin") {
        formattedEvent = "Check-In";
    } else if (event === "occupied") {
        formattedEvent = "During the Reservation";
    } else if (event === "checkout") {
        formattedEvent = "Check-Out";
    } else if (event === "doorUnlocked") {
        formattedEvent = "Door Was Unlocked";
    } else if (event === "checkinChanged") {
        formattedEvent = "Check-In Date Changed";
    } else if (event === "checkoutChanged") {
        formattedEvent = "Check-Out Date Changed";
    } else if (event === "numberOfGuestsChanged") {
        formattedEvent = "Number of Guests Changed";
    } else if (event === "cancelled") {
        formattedEvent = "Canceled Booking";
    } else {
        formattedEvent = formattedEvent[0].toUpperCase() + formattedEvent.slice(1).toLowerCase();
    }
    return formattedEvent;
}
function getLanguageText(rule) {
    let languageText = "";
    if (rule.languagesEnabled) {
        let languages;
        if (rule.reviewEnabled && rule.reviewMessages) {
            languages = Object.keys(rule.reviewMessages);
        } else if (rule.messages) {
            languages = Object.keys(rule.messages);
        } else {
            languages = [];
        }
        languages = languages.filter((language)=>{
            return language !== "default";
        });
        languageText = languages.join(", ").toLocaleUpperCase();
    }
    return languageText;
}
function getLimitDaysFormatted(limitDays) {
    return Object.keys(limitDays).reduce((result, day)=>{
        if (limitDays && limitDays[day]) {
            result.push(day.charAt(0).toUpperCase() + day.substring(1, 2));
        }
        return result;
    }, []).join(", ");
}
