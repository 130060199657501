"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "BillingService", {
    enumerable: true,
    get: function() {
        return BillingService;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class BillingService {
    async fetchBilling() {
        const { data } = await this.http.get("/getBilling");
        return data;
    }
    async updateFrequency(frequency) {
        const url = "/updateFrequency";
        const fields = {
            frequency
        };
        await this.http.post(url, fields);
    }
    async resubscribe() {
        const { data } = await this.http.post("/resubscribe");
        return data;
    }
    async cancelSubscription() {
        await this.http.post("/cancelSubscription");
    }
    async checkout(frequency) {
        const url = "/checkout";
        const fields = {
            frequency
        };
        const { data } = await this.http.post(url, fields);
        return data;
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
