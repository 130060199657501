"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _contexts = require("../../contexts");
const useWatchUser = ()=>{
    const websocket = (0, _react.useContext)(_contexts.WebSocketContext);
    const { user, setUser } = (0, _react.useContext)(_contexts.UserContext);
    (0, _react.useEffect)(()=>{
        function handleUserChange(payload) {
            if (payload.event === "update" && user._id === payload.document._id) {
                setUser(payload.document);
            }
        }
        if (websocket && user._id) {
            websocket.on("user", handleUserChange);
        }
        return ()=>{
            if (websocket && user._id) {
                websocket.off("user", handleUserChange);
            }
        };
    }, [
        websocket,
        user._id,
        setUser
    ]);
};
const _default = useWatchUser;
