"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "memoWithType", {
    enumerable: true,
    get: function() {
        return memoWithType;
    }
});
const _react = require("react");
const memoWithType = _react.memo;
