"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "addIntercomLead", {
    enumerable: true,
    get: function() {
        return addIntercomLead;
    }
});
function addIntercomLead(payload) {
    const url = "/addIntercomLead";
    return fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });
}
