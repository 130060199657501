"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "BatteryIcon", {
    enumerable: true,
    get: function() {
        return BatteryIcon;
    }
});
const _batterysvg = require("../../../assets/battery.svg");
const _iconMaker = require("./iconMaker");
const BatteryIcon = (0, _iconMaker.iconMaker)(_batterysvg.ReactComponent, "BatteryIcon");
