"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getFirstPath", {
    enumerable: true,
    get: function() {
        return getFirstPath;
    }
});
function getFirstPath(obj, path = "") {
    if (obj instanceof Object && !("type" in obj && "message" in obj && "ref" in obj)) {
        let result = "";
        Object.keys(obj).forEach((key)=>{
            if (result !== "" || obj[key] === undefined) {
                return;
            }
            const newPath = path ? `${path}.${key}` : `${key}`;
            result = getFirstPath(obj[key], newPath);
        });
        if (result !== "") {
            return result;
        }
    }
    return path;
}
