"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _AppProvider = require("../provider/AppProvider");
function useMobileView(mobileValue, desktopValue) {
    const { isMobile } = (0, _react.useContext)(_AppProvider.AppContext);
    if (isMobile) {
        return typeof mobileValue === "undefined" ? true : mobileValue;
    }
    return typeof desktopValue === "undefined" ? false : desktopValue;
}
const _default = useMobileView;
