"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "convertValuesToOptions", {
    enumerable: true,
    get: function() {
        return convertValuesToOptions;
    }
});
function convertValuesToOptions(values, options) {
    const selectedOptions = values.reduce((result, key)=>{
        const foundOption = options.find((option)=>{
            if (option.value === key) {
                return true;
            }
            return false;
        });
        if (foundOption) {
            result.push(foundOption);
        }
        return result;
    }, []);
    return selectedOptions;
}
