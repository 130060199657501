"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _thundericonsvg = require("../../../assets/thunder-icon.svg");
const _iconMaker = require("./iconMaker");
const ThunderIcon = (0, _iconMaker.iconMaker)(_thundericonsvg.ReactComponent, "ThunderIcon");
const _default = ThunderIcon;
