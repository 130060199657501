"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    DATE_FORMAT: function() {
        return DATE_FORMAT;
    },
    eventClassNames: function() {
        return eventClassNames;
    },
    generateDateTextByKey: function() {
        return generateDateTextByKey;
    },
    generateKeyOfWeekEvent: function() {
        return generateKeyOfWeekEvent;
    },
    getCalendarApi: function() {
        return getCalendarApi;
    },
    getNameFromReservation: function() {
        return getNameFromReservation;
    },
    getNumberOfLineOfEvent: function() {
        return getNumberOfLineOfEvent;
    },
    getPriceChannels: function() {
        return getPriceChannels;
    },
    getPricesRange: function() {
        return getPricesRange;
    },
    isSelectDateOccupied: function() {
        return isSelectDateOccupied;
    },
    transformPriceAndAvailabilityOverrides: function() {
        return transformPriceAndAvailabilityOverrides;
    }
});
const _constant = require("../../../../core/src/constant");
const _utilsreservation = require("../../../../core/src/shared/utils/utils.reservation");
const _datefns = require("date-fns");
const _lodash = require("lodash");
const _date = require("./date");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const DATE_FORMAT = "yyyy-MM-dd";
const getCalendarApi = (ref)=>{
    const { current } = ref;
    return current === null || current === void 0 ? void 0 : current.getApi();
};
function eventClassNames(params) {
    const { event, isStart, isEnd } = params;
    const { wrapperClass } = event.extendedProps;
    let classNames = `${wrapperClass} border-0`;
    if (isStart && isEnd) {
        classNames += " event-full";
    }
    if (!isStart) {
        classNames += " event-without-start";
    }
    if (!isEnd) {
        classNames += " event-without-end";
    }
    return classNames;
}
function getNameFromReservation(reservation) {
    const { source } = reservation;
    const fullName = (0, _utilsreservation.getFullName)(reservation);
    return fullName || source;
}
function generateKeyOfWeekEvent(id, weekDate) {
    return `${id}_${weekDate.getMonth()}_${weekDate.getDate()}`;
}
function getNumberOfLineOfEvent({ info, lineOfEventsMounted, weekStartsOn, reservations }) {
    const { isStart, isEnd, event } = info;
    const { id, extendedProps: { startDate: eventStartDate, endDate: eventEndDate } } = event;
    let weekIsRendering;
    const listWeeks = (0, _datefns.eachWeekOfInterval)({
        start: eventStartDate,
        end: eventEndDate
    }, {
        weekStartsOn
    });
    if (!isStart && isEnd) {
        weekIsRendering = (0, _datefns.startOfWeek)(eventEndDate, {
            weekStartsOn
        });
    } else if (isStart && !isEnd) {
        weekIsRendering = (0, _datefns.startOfWeek)(eventStartDate, {
            weekStartsOn
        });
    } else if (isStart && isEnd) {
        weekIsRendering = (0, _datefns.startOfWeek)(eventStartDate, {
            weekStartsOn
        });
    } else {
        // this case when event has no head and tail, we dont know what part of event, so we find the part by skip parts mounted before.
        weekIsRendering = listWeeks.slice(1, listWeeks.length - 1).find((w)=>lineOfEventsMounted[generateKeyOfWeekEvent(id, w)] === undefined);
    }
    if (!weekIsRendering) {
        return {
            lineOfEventShouldPlace: 0
        };
    }
    const beforeEvents = reservations.slice(0, reservations.findIndex((r)=>r.id === id));
    let checkDate = eventStartDate;
    if (!isStart && isEnd) {
        checkDate = (0, _datefns.startOfWeek)(eventEndDate, {
            weekStartsOn
        });
    } else if (!isStart && !isEnd) {
        checkDate = weekIsRendering;
    }
    const eventsOverlap = beforeEvents.filter((r)=>{
        return (0, _datefns.isWithinInterval)(checkDate, {
            start: r.startDate,
            end: r.endDate
        }) && !(0, _datefns.isSameDay)(eventStartDate, r.endDate);
    });
    // Use number of line of events overlap which has same week of this event(same week mean same  row)
    // to find out whether has empty place above this event.
    const lineEmptyAboveThisEvent = eventsOverlap.map((e)=>lineOfEventsMounted[generateKeyOfWeekEvent(e.id, weekIsRendering)]).reduce((arr, e)=>{
        arr[e] = 1;
        return arr;
    }, []).findIndex((e)=>!e);
    // if no line empty above this event, this event should place below events overlap it
    const lineOfEventShouldPlace = lineEmptyAboveThisEvent !== -1 ? lineEmptyAboveThisEvent : eventsOverlap.length;
    return {
        lineOfEventShouldPlace,
        weekIsRendering
    };
}
function isSelectDateOccupied(start, end, events) {
    const isOccupied = events.some((event)=>{
        if (!event.start || !event.end) {
            return false;
        }
        const eventDates = {
            start: event.start,
            end: (0, _datefns.isSameDay)(event.start, event.end) ? event.end : (0, _datefns.subDays)(event.end, 1)
        };
        const selectionIncludesReservation = (0, _datefns.isBefore)(start, eventDates.start) && (0, _datefns.isAfter)(end, eventDates.end);
        if (selectionIncludesReservation) {
            return true;
        }
        const startDateIsWithinReservation = (0, _datefns.isWithinInterval)(start, eventDates);
        if (startDateIsWithinReservation) {
            return true;
        }
        const endDateIsWithinReservation = (0, _datefns.isWithinInterval)(end, eventDates) && !(0, _datefns.isSameDay)(end, eventDates.start);
        return endDateIsWithinReservation;
    });
    return isOccupied;
}
function transformPriceAndAvailabilityOverrides(priceAndAvailabilityOverrides) {
    const { selectedDates, pricesOverride, availabilityOverride } = priceAndAvailabilityOverrides;
    if (selectedDates) {
        const pricesAndAvailabilityOverridesByDate = {};
        const dates = (0, _datefns.eachDayOfInterval)({
            start: selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates.startDate,
            end: selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates.endDate
        }).map((date)=>(0, _datefns.format)(date, DATE_FORMAT));
        if (pricesOverride) {
            Object.keys(pricesOverride).forEach((key)=>{
                if (key === "all" || _constant.providerChannels.includes(key)) {
                    var _pricesOverride_key, _pricesOverride_key1, _pricesOverride_key2, _pricesOverride_key3, _pricesOverride_key4;
                    pricesOverride[key] = _object_spread_props(_object_spread({}, pricesOverride === null || pricesOverride === void 0 ? void 0 : pricesOverride[key]), {
                        price: (pricesOverride === null || pricesOverride === void 0 ? void 0 : (_pricesOverride_key = pricesOverride[key]) === null || _pricesOverride_key === void 0 ? void 0 : _pricesOverride_key.priceOverride) ? Number(pricesOverride === null || pricesOverride === void 0 ? void 0 : (_pricesOverride_key1 = pricesOverride[key]) === null || _pricesOverride_key1 === void 0 ? void 0 : _pricesOverride_key1.priceOverride) : pricesOverride === null || pricesOverride === void 0 ? void 0 : (_pricesOverride_key2 = pricesOverride[key]) === null || _pricesOverride_key2 === void 0 ? void 0 : _pricesOverride_key2.price,
                        priceFormatted: (pricesOverride === null || pricesOverride === void 0 ? void 0 : (_pricesOverride_key3 = pricesOverride[key]) === null || _pricesOverride_key3 === void 0 ? void 0 : _pricesOverride_key3.priceOverrideFormatted) || (pricesOverride === null || pricesOverride === void 0 ? void 0 : (_pricesOverride_key4 = pricesOverride[key]) === null || _pricesOverride_key4 === void 0 ? void 0 : _pricesOverride_key4.priceFormatted)
                    });
                }
            });
        }
        dates.forEach((date)=>{
            pricesAndAvailabilityOverridesByDate[date] = _object_spread({}, pricesOverride, availabilityOverride ? {
                blocked: availabilityOverride.isOverrideAvailability,
                availabilityOverride: availabilityOverride.isOverrideAvailability
            } : undefined);
        });
        return pricesAndAvailabilityOverridesByDate;
    }
    return null;
}
const generateDateTextByKey = (priceList, ...[key, keyDisplay])=>{
    const sortedList = (0, _lodash.uniq)((0, _lodash.sortBy)(priceList, key).reduce((result, price)=>{
        const value = price === null || price === void 0 ? void 0 : price[keyDisplay];
        if (typeof value !== "undefined") {
            result.push(value);
        }
        return result;
    }, []));
    const first = sortedList[0];
    const last = sortedList.length > 1 ? sortedList[sortedList.length - 1] : undefined;
    if (typeof first === "undefined") {
        return "-";
    }
    if (typeof last === "undefined") {
        return `${first}`;
    }
    return `${first}-${last}`;
};
const getPriceChannels = (price, channels)=>{
    return channels.reduce((result, channel)=>{
        const priceChannel = price === null || price === void 0 ? void 0 : price[channel];
        if (priceChannel) {
            result.push(priceChannel);
        }
        return result;
    }, []);
};
function getPricesRange(prices, start, end) {
    return (0, _datefns.eachDayOfInterval)({
        start,
        end
    }).map((date)=>prices[(0, _date.formatyyyyMMdd)(date)]).filter(Boolean);
}
