"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "PaperIcon", {
    enumerable: true,
    get: function() {
        return PaperIcon;
    }
});
const _papericonsvg = require("../../../assets/paper-icon.svg");
const _iconMaker = require("./iconMaker");
const PaperIcon = (0, _iconMaker.iconMaker)(_papericonsvg.ReactComponent, "PaperIcon");
