"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class TimelineService {
    async fetchTimelines(reservationID, config) {
        try {
            const url = `/timelines/${reservationID}`;
            const { data } = await this.http.get(url, config);
            return data;
        } catch (e) {
            return undefined;
        }
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
const _default = TimelineService;
