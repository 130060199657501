"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useEllipsisVisible", {
    enumerable: true,
    get: function() {
        return useEllipsisVisible;
    }
});
const _react = require("react");
const useEllipsisVisible = (disabled = false, numberOfLines = 1)=>{
    const [overflowingNode, setOverflowingNode] = (0, _react.useState)();
    const [isEllipsisVisible, setEllipsisVisible] = (0, _react.useState)(false);
    const ellipsisVisibleRef = (0, _react.useCallback)((node)=>{
        if (node instanceof HTMLElement) {
            setOverflowingNode(node);
        }
    }, []);
    (0, _react.useEffect)(()=>{
        if (disabled) {
            return;
        }
        if (overflowingNode instanceof HTMLElement) {
            if (numberOfLines > 1) {
                const lineHeight = parseFloat(getComputedStyle(overflowingNode).lineHeight);
                const maxHeight = lineHeight * numberOfLines;
                setEllipsisVisible(overflowingNode.scrollHeight > maxHeight);
                return;
            }
            setEllipsisVisible(overflowingNode.scrollWidth > overflowingNode.offsetWidth);
        }
    }, [
        disabled,
        numberOfLines,
        overflowingNode
    ]);
    return [
        isEllipsisVisible,
        ellipsisVisibleRef
    ];
};
