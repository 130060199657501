"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ListingIcon", {
    enumerable: true,
    get: function() {
        return ListingIcon;
    }
});
const _listingsvg = require("../../../assets/listing.svg");
const _iconMaker = require("./iconMaker");
const ListingIcon = (0, _iconMaker.iconMaker)(_listingsvg.ReactComponent, "ListingIcon");
