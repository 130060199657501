"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "taxTypes", {
    enumerable: true,
    get: function() {
        return taxTypes;
    }
});
const taxTypes = [
    {
        value: "SalesTaxExcluded",
        label: "Sales Tax Excluded"
    },
    {
        value: "SalesTaxIncluded",
        label: "Sales Tax Included"
    }
];
