"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _constant = require("../../../../core/src/constant");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class PricingService {
    async getPrices(listingID, starting) {
        const url = `/getPrices/${listingID}/${starting}`;
        const { data } = await this.http.get(url);
        return data;
    }
    async getAirbticsData(listingID) {
        const url = `/getAirbtics/${listingID}`;
        const { data } = await this.http.get(url);
        return data;
    }
    async setPriceOverride(startDate, endDate, listingID, prices) {
        var _prices_all;
        const url = "/setPriceOverride";
        const allChannelPriceOverride = (_prices_all = prices.all) === null || _prices_all === void 0 ? void 0 : _prices_all.priceOverride;
        const pricesObjectKeys = Object.keys(prices);
        const allOtherChannelsSamePriceWithAllChannelPrice = pricesObjectKeys.every((channel)=>{
            if (_constant.providerChannels.includes(channel)) {
                const channelPrice = prices[channel];
                return (channelPrice === null || channelPrice === void 0 ? void 0 : channelPrice.priceOverride) === allChannelPriceOverride;
            }
            return true;
        });
        const hasNoAnyPriceOverride = pricesObjectKeys.every((channel)=>{
            if (_constant.providerChannels.includes(channel)) {
                var _prices_channel;
                return !((_prices_channel = prices[channel]) === null || _prices_channel === void 0 ? void 0 : _prices_channel.priceOverride);
            }
            return true;
        });
        const newPrices = {};
        if (allChannelPriceOverride && allOtherChannelsSamePriceWithAllChannelPrice) {
            newPrices.all = {
                price: allChannelPriceOverride
            };
        } else if (!hasNoAnyPriceOverride) {
            pricesObjectKeys.forEach((channel)=>{
                if (_constant.providerChannels.includes(channel)) {
                    const channelPrice = prices[channel];
                    newPrices[channel] = {
                        price: (channelPrice === null || channelPrice === void 0 ? void 0 : channelPrice.priceOverride) || (channelPrice === null || channelPrice === void 0 ? void 0 : channelPrice.price)
                    };
                }
            });
        }
        const { data } = await this.http.post(url, {
            startDate,
            endDate,
            listingID,
            prices: newPrices
        });
        return data;
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
const _default = PricingService;
