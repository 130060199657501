"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const getFileExtFromUrl = (url)=>{
    // this matches ext only such as `.png`
    // const matches = url.match(/\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim);
    const matches = url.match(/([\w-]+)(\.[\w-]+)+(?!.*\/)/g);
    const ext = (matches === null || matches === void 0 ? void 0 : matches.length) ? matches[0] : undefined;
    return ext;
};
const _default = getFileExtFromUrl;
