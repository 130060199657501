"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "BlockCheckinIcon", {
    enumerable: true,
    get: function() {
        return BlockCheckinIcon;
    }
});
const _blockcheckiniconsvg = require("../../../assets/block-checkin-icon.svg");
const _iconMaker = require("./iconMaker");
const BlockCheckinIcon = (0, _iconMaker.iconMaker)(_blockcheckiniconsvg.ReactComponent, "BlockCheckinIcon");
