"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _messageiconsvg = require("../../../assets/message-icon.svg");
const _iconMaker = require("./iconMaker");
const MessageIcon = (0, _iconMaker.iconMaker)(_messageiconsvg.ReactComponent, "MessageIcon");
const _default = MessageIcon;
