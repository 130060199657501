"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "checkForSpecialTags", {
    enumerable: true,
    get: function() {
        return checkForSpecialTags;
    }
});
const checkForSpecialTags = (value)=>{
    const warnings = [];
    if (value.search("{{Previous Check-In Date}}") !== -1) {
        warnings.push("The {{Previous Check-In Date}} tag will not populate unless you use the 'Check-In Changed' event.");
    }
    if (value.search("{{Previous Number of Nights}}") !== -1) {
        warnings.push("The {{Previous Number of Nights}} tag will not populate unless you use the 'Check-In Changed' or 'Check-Out Changed' events.");
    }
    if (value.search("{{Previous Check-Out Date}}") !== -1) {
        warnings.push("The {{Previous Check-Out Date}} tag will not populate unless you use the 'Check-Out Changed' event.");
    }
    if (value.search("{{Previous Number of Guests}}") !== -1) {
        warnings.push("The {{Previous Number of Guests}} tag will not populate unless you use the 'Number of Guests Changed' event.");
    }
    return warnings;
};
