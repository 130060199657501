"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useLockService", {
    enumerable: true,
    get: function() {
        return useLockService;
    }
});
const _react = require("react");
const _services = require("../../../services");
const _contexts = require("../../contexts");
const useLockService = ()=>{
    const http = (0, _react.useContext)(_contexts.HTTPContext);
    return (0, _react.useMemo)(()=>new _services.LockService(http), [
        http
    ]);
};
