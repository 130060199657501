"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getLockName", {
    enumerable: true,
    get: function() {
        return getLockName;
    }
});
function getLockName(lock) {
    return lock.nickname || lock.name || "";
}
