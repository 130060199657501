"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useWebSocket", {
    enumerable: true,
    get: function() {
        return useWebSocket;
    }
});
const _react = require("react");
const _socketioclient = require("socket.io-client");
const useWebSocket = (url, init)=>{
    const [ws, setWS] = (0, _react.useState)();
    (0, _react.useEffect)(()=>{
        if (init) {
            const socket = (0, _socketioclient.io)(url, {
                withCredentials: true,
                transports: [
                    "websocket"
                ]
            });
            socket.on("connect_error", (err)=>{
                if (__DEV__) {
                    console.warn(`connect_error due to ${err.message}`);
                }
            });
            socket.on("connect", ()=>{
                if (__DEV__) {
                    console.log("websocket connected!");
                }
                // it actually subscribes the events as it has been connected
                setWS(socket);
            });
            socket.on("disconnect", ()=>{
                if (__DEV__) {
                    console.log("websocket disconnected :(");
                }
            });
        }
    }, [
        init,
        url
    ]);
    (0, _react.useEffect)(()=>{
        return ()=>{
            if (ws) {
                ws.disconnect();
                ws.close();
            }
        };
    }, [
        ws
    ]);
    return ws;
};
