"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ChainIcon", {
    enumerable: true,
    get: function() {
        return ChainIcon;
    }
});
const _chainsvg = require("../../../assets/chain.svg");
const _iconMaker = require("./iconMaker");
const ChainIcon = (0, _iconMaker.iconMaker)(_chainsvg.ReactComponent, "ChainIcon");
