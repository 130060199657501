"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _listing = require("../../models/listing");
const _file = require("../../utils/file");
const _base = require("../base");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
class ListingService extends _base.Base {
    async fetchAllListings() {
        const url = "/listings/all";
        const { data } = await this.http.get(url);
        return (0, _listing.sanitizeListings)(data);
    }
    async fetchAllListingGroups() {
        const url = "/listingGroups";
        const res = await this.http.get(url);
        return res.data;
    }
    async fetchListingsFromAccount(accountID) {
        const url = `/listingsFromChannel/${accountID}`;
        const { data } = await this.http.get(url);
        // merge them into a single type probably a mini listing type
        return data.map((elem)=>{
            // differentiate listing type by return value for now
            let _id;
            if ("channels.Airbnb.id" in elem) {
                _id = elem["channels.Airbnb.id"];
            } else if ("channels.Booking.id" in elem) {
                _id = elem["channels.Booking.id"];
            } else if ("channels.Houfy.id" in elem) {
                _id = elem["channels.Houfy.id"];
            } else {
                _id = elem["channels.HomeAway.id"];
            }
            if (!_id) {
                throw new Error("No external listingID found");
            }
            return {
                _id,
                name: elem.airbnbName,
                airbnbThumbnailUrl: "airbnbThumbnailUrl" in elem ? elem.airbnbThumbnailUrl : "",
                channels: {},
                isConnected: elem.isConnected
            };
        });
    }
    async fetchListingObjectFromChannel({ listingID, channel }) {
        const url = "/listingObjFromChannel";
        const { data } = await this.http.post(url, {
            listingID,
            channel
        });
        return data;
    }
    async importListings(accountID, externalListingIDs) {
        await this.http.post("/importListingsFromChannel", {
            accountID,
            externalListingIDs
        });
    }
    async connectListingToAccount(accountID, listingID, externalListingID) {
        await this.http.post("/connectListingToAccount", {
            accountID,
            listingID,
            externalListingID
        });
    }
    async disconnectListingFromChannel(listingID, channel) {
        await this.http.post("/disconnectListingFromChannel", {
            listingID,
            channel
        });
    }
    async getChannelSetting(listingID, accountID) {
        var _data_defaultPricingRules, _data_seasonalRules, _data_passThroughTaxes, _data_standardFees;
        const url = `/getChannelSettings?listingID=${listingID}&accountID=${accountID}`;
        const response = await this.http.get(url);
        const { data } = response;
        data.defaultPricingRules = (_data_defaultPricingRules = data.defaultPricingRules) === null || _data_defaultPricingRules === void 0 ? void 0 : _data_defaultPricingRules.map((rule, idx)=>_object_spread_props(_object_spread({}, rule), {
                id: `${idx}`
            }));
        data.seasonalRules = (_data_seasonalRules = data.seasonalRules) === null || _data_seasonalRules === void 0 ? void 0 : _data_seasonalRules.map((rule)=>{
            var _rule_pricingRules;
            return _object_spread_props(_object_spread({}, rule), {
                pricingRules: (_rule_pricingRules = rule.pricingRules) === null || _rule_pricingRules === void 0 ? void 0 : _rule_pricingRules.map((pricingRule, idx)=>_object_spread_props(_object_spread({}, pricingRule), {
                        id: `${idx}`
                    }))
            });
        });
        data.passThroughTaxes = (_data_passThroughTaxes = data.passThroughTaxes) === null || _data_passThroughTaxes === void 0 ? void 0 : _data_passThroughTaxes.map((tax, idx)=>_object_spread_props(_object_spread({}, tax), {
                id: `${idx}`
            }));
        data.standardFees = (_data_standardFees = data.standardFees) === null || _data_standardFees === void 0 ? void 0 : _data_standardFees.map((fee, idx)=>_object_spread_props(_object_spread({}, fee), {
                id: `${idx}`
            }));
        return data;
    }
    async uploadImage(_param, config) {
        var { listingID } = _param, rest = _object_without_properties(_param, [
            "listingID"
        ]);
        const url = "/uploadListingImage";
        const formData = (0, _file.generateImageUploadFormData)(rest);
        formData.append("id", listingID);
        const { data } = await this.http.post(url, formData, _object_spread({
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, config));
        return data;
    }
    async deleteImages({ keys }, config) {
        const url = "/deleteListingImages";
        const { data } = await this.http.post(url, {
            keys
        }, config);
        return data;
    }
    async submitChannelSetting(listingID, accountID, channelSettingsObj) {
        const url = "/setChannelSettings";
        await this.http.post(url, {
            listingID,
            accountID,
            channelSettingsObj
        });
    }
    async deleteListing(id) {
        const url = "/deleteListing";
        await this.http.delete(url, {
            data: {
                id
            }
        });
    }
    async getListingAttributeOptions() {
        const { data } = await this.http.get("/listingAttributeOptions");
        return data;
    }
}
const _default = ListingService;
