"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "Alert", {
    enumerable: true,
    get: function() {
        return Alert;
    }
});
const _nativebase = require("native-base");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const Alert = {
    baseStyle: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
        p: 3,
        space: 3,
        borderRadius: "0"
    },
    variants: _object_spread_props(_object_spread({}, _nativebase.theme.components.Alert.variants), {
        solid: (props)=>{
            let { colorScheme } = props;
            const { status } = props;
            colorScheme = status || colorScheme;
            return {
                px: 8,
                py: 3,
                bg: `${colorScheme}.50`,
                borderTopWidth: 0,
                borderTopColor: "transparent",
                borderBottomWidth: 1,
                borderBottomColor: `${colorScheme}.300`,
                _icon: {
                    color: `${colorScheme}.600`
                }
            };
        },
        subtle: (props)=>{
            let { colorScheme } = props;
            const { status } = props;
            colorScheme = status || colorScheme;
            return {
                p: 0,
                bg: "transparent",
                _icon: {
                    color: `${colorScheme}.600`
                }
            };
        }
    }),
    defaultProps: {
        colorScheme: "success",
        variant: "solid"
    }
};
