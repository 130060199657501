"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _frontend = require("../../../../frontend/src/index");
const _react = require("react");
const useAccountsByType = (accountType)=>{
    const { accounts } = (0, _react.useContext)(_frontend.UserContext);
    const { channels } = (0, _frontend.useAccounts)(accounts);
    return (0, _react.useMemo)(()=>channels.filter((elem)=>elem.type === accountType), [
        accountType,
        channels
    ]);
};
const _default = useAccountsByType;
