"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _peoplesvg = require("../../../assets/people.svg");
const _iconMaker = require("./iconMaker");
const PeopleIcon = (0, _iconMaker.iconMaker)(_peoplesvg.ReactComponent, "PeopleIcon");
const _default = PeopleIcon;
