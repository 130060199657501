"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ThreeDotVerticalIcon", {
    enumerable: true,
    get: function() {
        return ThreeDotVerticalIcon;
    }
});
const _threedotverticaliconsvg = require("../../../assets/three-dot-vertical-icon.svg");
const _iconMaker = require("./iconMaker");
const ThreeDotVerticalIcon = (0, _iconMaker.iconMaker)(_threedotverticaliconsvg.ReactComponent, "ThreeDotVerticalIcon");
