"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "boxStyle", {
    enumerable: true,
    get: function() {
        return boxStyle;
    }
});
const _IsWeb = require("@gluestack-ui/nativewind-utils/IsWeb");
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const baseStyle = _IsWeb.isWeb ? "flex flex-col relative z-0 box-border border-0 list-none min-w-0 min-h-0 bg-transparent items-stretch text-decoration-none" : "";
const boxStyle = (0, _tva.tva)({
    base: baseStyle
});
