"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const isImage = (filename)=>{
    let ext = filename;
    if (ext.includes(".")) {
        const parts = ext.split(".");
        ext = parts[parts.length - 1];
    }
    return /(jpg|jpeg|png|webp|avif|gif|svg)$/.test(ext);
};
const _default = isImage;
