"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "centerStyle", {
    enumerable: true,
    get: function() {
        return centerStyle;
    }
});
const _IsWeb = require("@gluestack-ui/nativewind-utils/IsWeb");
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const baseStyle = _IsWeb.isWeb ? "flex flex-col relative z-0" : "";
const centerStyle = (0, _tva.tva)({
    base: `justify-center items-center ${baseStyle}`
});
