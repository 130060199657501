"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "GearIcon", {
    enumerable: true,
    get: function() {
        return GearIcon;
    }
});
const _gearsiconsvg = require("../../../assets/gears-icon.svg");
const _iconMaker = require("./iconMaker");
const GearIcon = (0, _iconMaker.iconMaker)(_gearsiconsvg.ReactComponent, "GearIcon");
