"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "components", {
    enumerable: true,
    get: function() {
        return components;
    }
});
const _Alert = require("./Alert");
const _Badge = require("./Badge");
const _Button = require("./Button");
const _constant = require("./constant");
const _IconButton = require("./IconButton");
const _Input = require("./Input");
const _Toast = require("./Toast");
const baseStyle = ()=>{
    return {};
};
const components = {
    components: {
        Input: _Input.InputTheme,
        Text: {
            baseStyle,
            defaultProps: {
                color: "blueGray.600",
                size: "md",
                fontFamily: "body"
            },
            sizes: {
                "4xl": {
                    fontSize: "36px"
                },
                "3xl": {
                    fontSize: "30px"
                },
                "2xl": {
                    fontSize: "24px"
                },
                xl: {
                    fontSize: "20px"
                },
                lg: {
                    fontSize: "18px"
                },
                md: {
                    fontSize: "16px"
                },
                sm: {
                    fontSize: "14px"
                },
                xs: {
                    fontSize: "12px"
                },
                "2xs": {
                    fontSize: "10px"
                }
            }
        },
        Heading: {
            defaultProps: {
                color: "blueGray.800",
                size: "xl"
            },
            sizes: {
                "4xl": {
                    fontSize: [
                        "60px"
                    ]
                },
                "3xl": {
                    fontSize: [
                        "42px",
                        "48px"
                    ]
                },
                "2xl": {
                    fontSize: [
                        "32px",
                        "36px"
                    ]
                },
                xl: {
                    fontSize: [
                        "28px",
                        "32px"
                    ]
                },
                lg: {
                    fontSize: [
                        "20px",
                        "24px"
                    ]
                },
                md: {
                    fontSize: "20px"
                },
                sm: {
                    fontSize: "16px"
                },
                xs: {
                    fontSize: "14px"
                }
            }
        },
        View: {
            baseStyle: {},
            defaultProps: {
                zIndex: "unset"
            }
        },
        Box: {
            baseStyle: {},
            defaultProps: {
                zIndex: "unset"
            }
        },
        Stack: {
            baseStyle: {},
            defaultProps: {
                zIndex: "unset"
            },
            sizes: {}
        },
        Badge: _Badge.Badge,
        Popover: {
            baseStyle: ()=>{
                return {
                    _overlay: {
                        style: {
                            zIndex: _constant.ZINDEX_POPOVER
                        },
                        unmountOnExit: true
                    }
                };
            }
        },
        Modal: {
            sizes: {
                xs: {
                    contentSize: {
                        width: "60%",
                        maxWidth: "280"
                    }
                },
                sm: {
                    contentSize: {
                        width: "65%",
                        maxWidth: "320"
                    }
                },
                md: {
                    contentSize: {
                        width: "75%",
                        maxWidth: "500"
                    }
                },
                lg: {
                    contentSize: {
                        width: "80%",
                        maxWidth: "800"
                    }
                },
                xl: {
                    contentSize: {
                        width: "90%",
                        maxWidth: "1000"
                    }
                },
                full: {
                    contentSize: {
                        width: "100%"
                    }
                }
            },
            baseStyle: {
                _overlay: {
                    style: {
                        zIndex: _constant.ZINDEX_MODAL
                    }
                }
            }
        },
        FormControlErrorMessage: {
            defaultProps: {
                _text: {
                    fontSize: "sm",
                    fontWeight: "normal"
                }
            }
        },
        FormControl: {
            defaultProps: {
                zIndex: "auto"
            }
        },
        Checkbox: {
            sizes: {
                lg: {
                    _icon: {
                        size: 4
                    },
                    _text: {
                        fontSize: "md"
                    }
                },
                md: {
                    _icon: {
                        size: 3
                    },
                    _text: {
                        fontSize: "sm"
                    }
                },
                sm: {
                    _icon: {
                        size: 3
                    },
                    _text: {
                        fontSize: "xs"
                    }
                }
            }
        },
        IconButton: _IconButton.IconButtonTheme,
        TextArea: {
            baseStyle: {
                multiline: true,
                p: "2",
                textAlignVertical: "top",
                h: 40,
                totalLines: 5
            }
        },
        Alert: _Alert.Alert,
        Menu: {
            baseStyle: {
                _overlay: {
                    style: {
                        zIndex: _constant.ZINDEX_POPOVER
                    }
                }
            }
        },
        PopoverBody: {
            baseStyle: ()=>({
                    p: "3",
                    shadow: "6",
                    bg: "white",
                    _text: {
                        color: "text.800"
                    }
                })
        },
        PopoverContent: {
            baseStyle: ()=>({
                    // TODO: Box inside PopperContent is not able to resolve shadow
                    // shadow: '6',
                    borderColor: "blueGray.200",
                    _text: {
                        color: "text.900"
                    },
                    borderWidth: 1,
                    rounded: "lg",
                    overflow: "hidden",
                    style: {
                        boxShadow: "rgba(30, 41, 59, 0.1) 0px 4px 6px 0px, rgba(30, 41, 59, 0.1) 0px 2px 4px 0px"
                    }
                })
        },
        PopoverArrow: {
            baseStyle: ()=>({
                    bg: "white",
                    borderColor: "blueGray.200",
                    borderTopLeftRadius: 8
                })
        },
        Button: _Button.Button,
        Toast: _Toast.Toast
    }
};
