"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "postFillerPrefix", {
    enumerable: true,
    get: function() {
        return postFillerPrefix;
    }
});
const postFillerPrefix = "addedAt-";
