"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "formatPrice", {
    enumerable: true,
    get: function() {
        return formatPrice;
    }
});
function formatPrice(value, currencySymbol) {
    let newValue = `${value}`;
    if (value >= 1000) {
        const suffixes = [
            "",
            "k",
            "m",
            "b",
            "t"
        ];
        const suffixNum = Math.floor((`${value}`.length - 1) / 3);
        const shortValue = parseFloat((suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(2));
        newValue = shortValue + suffixes[suffixNum];
    }
    return `${currencySymbol}${newValue}`;
}
