"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function stringPathFromObject(obj, prefix = "", store = {}) {
    Object.keys(obj).forEach((key)=>{
        const curPath = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === "object") {
            stringPathFromObject(obj[key], curPath, store);
        } else {
            store[curPath] = obj[key];
        }
    });
    return store;
}
const _default = stringPathFromObject;
