"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "sanitizeLock", {
    enumerable: true,
    get: function() {
        return sanitizeLock;
    }
});
const _utilslock = require("../../../core/src/shared/utils/utils.lock");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function sanitizeLock(lock) {
    var _lock_august_lock, _lock_august;
    var _lock_manufacturer, _lock_listingIDs;
    return _object_spread_props(_object_spread({}, lock), {
        // `nickname` could be an empty string
        name: (0, _utilslock.getLockName)(lock),
        manufacturer: (_lock_manufacturer = lock.manufacturer) !== null && _lock_manufacturer !== void 0 ? _lock_manufacturer : "",
        batteryLevel: lock.seam ? lock.batteryLevel : (_lock_august = lock.august) === null || _lock_august === void 0 ? void 0 : (_lock_august_lock = _lock_august.lock) === null || _lock_august_lock === void 0 ? void 0 : _lock_august_lock.battery,
        isLock: true,
        listingIDs: (_lock_listingIDs = lock.listingIDs) !== null && _lock_listingIDs !== void 0 ? _lock_listingIDs : []
    });
}
