"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useEventListener", {
    enumerable: true,
    get: function() {
        return useEventListener;
    }
});
const _react = require("react");
function useEventListener(event, handler, element, options) {
    const savedHandler = (0, _react.useRef)(handler);
    (0, _react.useLayoutEffect)(()=>{
        savedHandler.current = handler;
    }, [
        handler
    ]);
    const listener = (0, _react.useCallback)((event)=>{
        savedHandler.current(event);
    }, []);
    (0, _react.useEffect)(()=>{
        var _element_current;
        const targetElement = (_element_current = element === null || element === void 0 ? void 0 : element.current) !== null && _element_current !== void 0 ? _element_current : window;
        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }
        targetElement.addEventListener(event, listener, options);
        return ()=>{
            targetElement.removeEventListener(event, listener, options);
        };
    }, [
        event,
        element,
        options,
        listener
    ]);
}
