"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const Languages = [
    {
        value: "default",
        label: "Default",
        isFixed: true
    },
    {
        value: "id",
        label: "Bahasa Indonesia"
    },
    {
        value: "ms",
        label: "Bahasa Melayu"
    },
    {
        value: "ca",
        label: "Català"
    },
    {
        value: "da",
        label: "Dansk"
    },
    {
        value: "de",
        label: "Deutsch"
    },
    {
        value: "en",
        label: "English"
    },
    {
        value: "es",
        label: "Español"
    },
    {
        value: "el",
        label: "Eλληνικά"
    },
    {
        value: "fr",
        label: "Français"
    },
    {
        value: "hr",
        label: "Hrvatski"
    },
    {
        value: "it",
        label: "Italiano"
    },
    {
        value: "hu",
        label: "Magyar"
    },
    {
        value: "nl",
        label: "Nederlands"
    },
    {
        value: "no",
        label: "Norsk"
    },
    {
        value: "pl",
        label: "Polski"
    },
    {
        value: "pt",
        label: "Português"
    },
    {
        value: "fi",
        label: "Suomi"
    },
    {
        value: "sv",
        label: "Svenska"
    },
    {
        value: "tr",
        label: "Türkçe"
    },
    {
        value: "is",
        label: "Íslenska"
    },
    {
        value: "cs",
        label: "Čeština"
    },
    {
        value: "ru",
        label: "Русский"
    },
    {
        value: "he",
        label: "עברית"
    },
    {
        value: "ar",
        label: "العربية"
    },
    {
        value: "th",
        label: "ภาษาไทย"
    },
    {
        value: "ja",
        label: "日本語"
    },
    {
        value: "zh",
        label: "简体中文"
    },
    {
        value: "zh-TW",
        label: "繁體中文"
    },
    {
        value: "ko",
        label: "한국어"
    }
];
const _default = Languages;
