"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class ListingGroupService {
    async deleteListingGroup(id) {
        await this.http.post("/deleteListingGroup", {
            _id: id
        });
    }
    async addListingGroup(params) {
        await this.http.post("/addListingGroup", params);
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
const _default = ListingGroupService;
