"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _account = require("../../models/account");
const _subUser = require("../../models/subUser");
const _user = require("../../models/user");
const _base = require("../base");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
class UserService extends _base.Base {
    async fetchUser() {
        const { data } = await this.http.get("/getUser");
        return new _user.User(data);
    }
    async fetchAccounts() {
        const { data } = await this.http.get("/accounts");
        return data.map(_account.sanitizeAccount);
    }
    async fetchSeamClientToken() {
        try {
            const { data } = await this.http.get("/seam/clientToken");
            return data.token;
        } catch (e) {
            // still allow to app keep running
            return "";
        }
    }
    async deleteStaffUser(userID, username) {
        const { data } = await this.http.post("/deleteStaffUser", {
            _id: userID,
            username
        });
        return data;
    }
    async fetchSubUsers(params) {
        const data = await this.get("/getUsers", undefined, params);
        return _object_spread_props(_object_spread({}, data), {
            docs: data.docs.map((doc)=>new _subUser.SubUser(doc))
        });
    }
    async addSubUser(params) {
        await this.http.post("/addStaffUser", params);
    }
    async editSubUser(params) {
        await this.http.post("/editStaffUser", params);
    }
    async generateAuthToken() {
        const { data } = await this.http.post("/generateAuthToken");
        return data;
    }
    async editApiKey(payload) {
        const { data } = await this.http.post("/editApiKey", payload);
        return data;
    }
    async register(payload) {
        const { data } = await this.http.post("/register", payload);
        return data;
    }
    async login(payload) {
        const { data } = await this.http.post("/login", payload);
        return data;
    }
    async forgotPassword(username) {
        const { data } = await this.http.post("/forgot", {
            username
        });
        return data;
    }
    async resetPassword(resetPasswordToken, password) {
        const { data } = await this.http.post("/reset", {
            resetPasswordToken,
            password
        });
        return data;
    }
}
const _default = UserService;
