"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class LockService {
    async saveLock(lock, pinLength, removingListingIDs) {
        var _lock_nickname;
        await this.http.post("/editLockSettings", {
            lockID: lock._id,
            nickname: (_lock_nickname = lock.nickname) === null || _lock_nickname === void 0 ? void 0 : _lock_nickname.trim(),
            checkInBuffer: lock.checkInBuffer,
            checkOutBuffer: lock.checkOutBuffer,
            pinLength,
            removingListingIDs
        });
    }
    async deleteLock(lockID) {
        return this.http.delete("/deleteLock", {
            data: {
                lockID
            }
        });
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
const _default = LockService;
