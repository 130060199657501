"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _constant = require("../../constant");
function getTurnoverUrl(accessCode, isBeta) {
    let turnoverUrl = `${_constant.PROD_HOST}/turnovers/${accessCode}`;
    if (isBeta) {
        turnoverUrl = `${_constant.BETA_HOST}/turnovers/${accessCode}`;
    }
    return turnoverUrl;
}
const _default = getTurnoverUrl;
