"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function formatTime(timeInHour) {
    let period = "AM";
    if (timeInHour > 11) {
        period = "PM";
    }
    let timeNumber = timeInHour;
    if (timeInHour === 0) {
        timeNumber = 12;
    } else if (timeInHour > 12) {
        timeNumber = timeInHour - 12;
    }
    return `${timeNumber}:00 ${period}`;
}
const _default = formatTime;
