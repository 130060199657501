"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "BillingContext", {
    enumerable: true,
    get: function() {
        return BillingContext;
    }
});
const _react = require("react");
const BillingContext = (0, _react.createContext)({
    billing: undefined,
    onRefresh: ()=>Promise.resolve()
});
