"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useEditListingGroupPermission", {
    enumerable: true,
    get: function() {
        return useEditListingGroupPermission;
    }
});
const _react = require("react");
const _contexts = require("../../contexts");
function useEditListingGroupPermission(id) {
    const { user: { isSubUser, canEditListingGroup, listingGroupIDs } } = (0, _react.useContext)(_contexts.UserContext);
    const isAssignedListingGroup = (0, _react.useMemo)(()=>isSubUser && id && (listingGroupIDs === null || listingGroupIDs === void 0 ? void 0 : listingGroupIDs.includes(id)), [
        id,
        isSubUser,
        listingGroupIDs
    ]);
    return {
        canEdit: canEditListingGroup && !isAssignedListingGroup,
        warnMessage: canEditListingGroup ? isAssignedListingGroup ? "You cannot edit the listing group you are assigned to" : undefined : undefined
    };
}
