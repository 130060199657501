"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "usePrevious", {
    enumerable: true,
    get: function() {
        return usePrevious;
    }
});
const _react = require("react");
function usePrevious(value) {
    const ref = (0, _react.useRef)(value);
    (0, _react.useEffect)(()=>{
        ref.current = value;
    });
    return ref.current;
}
