"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _frontend = require("../../../../../frontend/src/index");
const _react = require("react");
const _useWatchMessageRule = require("../../../admin/hooks/useWatchMessageRule");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const TOTAL_STEPS = 5;
function useQuickGuide() {
    const { listings, user } = (0, _frontend.useUserContext)();
    const [messageRules] = (0, _useWatchMessageRule.useWatchMessageRule)(undefined, true);
    const [quickGuide, setQuickGuide] = (0, _react.useState)();
    const numberCompleted = (0, _react.useMemo)(()=>quickGuide ? Object.keys(quickGuide).filter((key)=>quickGuide === null || quickGuide === void 0 ? void 0 : quickGuide[key]).length + 1 : undefined, [
        quickGuide
    ]);
    (0, _react.useEffect)(()=>{
        if (listings && (user === null || user === void 0 ? void 0 : user.accounts) && messageRules) {
            setQuickGuide((prev)=>_object_spread_props(_object_spread({}, prev, listings.reduce((result, listing)=>{
                    if (listing.basePrice || listing.minPrice || listing.priceSource) {
                        result.configPricing = true;
                    }
                    if (listing.listingEnabled) {
                        result.enableListing = true;
                    }
                    return result;
                }, {})), {
                    connectAccount: !!user.accounts.find((account)=>!!account.lastLoginAttemptSuccessful),
                    enableMessageRule: !!messageRules.find((messageRule)=>!messageRule.paused)
                }));
        }
    }, [
        listings,
        messageRules,
        user.accounts
    ]);
    return (0, _react.useMemo)(()=>({
            quickGuide,
            numberCompleted,
            isAllStepCompleted: numberCompleted === TOTAL_STEPS,
            totalSteps: TOTAL_STEPS
        }), [
        numberCompleted,
        quickGuide
    ]);
}
const _default = useQuickGuide;
