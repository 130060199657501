"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    chargeTypeOptions: function() {
        return chargeTypeOptions;
    },
    feeUnitTypeOptions: function() {
        return feeUnitTypeOptions;
    },
    mapLabelFeeType: function() {
        return mapLabelFeeType;
    },
    mapLabelFeeUnitType: function() {
        return mapLabelFeeUnitType;
    },
    offlineOptions: function() {
        return offlineOptions;
    },
    optionsFeeType: function() {
        return optionsFeeType;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const optionsFeeType = [
    {
        label: "Resort fee",
        value: "PASS_THROUGH_RESORT_FEE"
    },
    {
        label: "Community fee",
        value: "PASS_THROUGH_COMMUNITY_FEE"
    },
    {
        label: "Management fee",
        value: "PASS_THROUGH_MANAGEMENT_FEE"
    },
    {
        label: "Linen fee",
        value: "PASS_THROUGH_LINEN_FEE"
    },
    {
        label: "Cleaning fee",
        value: "PASS_THROUGH_CLEANING_FEE"
    },
    {
        label: "Short-term cleaning fee",
        value: "PASS_THROUGH_SHORT_TERM_CLEANING_FEE"
    },
    {
        label: "Electricity fee",
        value: "PASS_THROUGH_ELECTRICITY_FEE"
    },
    {
        label: "Water fee",
        value: "PASS_THROUGH_WATER_FEE"
    },
    {
        label: "Heating fee",
        value: "PASS_THROUGH_HEATING_FEE"
    },
    {
        label: "Air conditioning fee",
        value: "PASS_THROUGH_AIR_CONDITIONING_FEE"
    },
    {
        label: "Utility fee",
        value: "PASS_THROUGH_UTILITY_FEE"
    } // flat
];
const mapLabelFeeType = optionsFeeType.reduce((map, opt)=>_object_spread_props(_object_spread({}, map), {
        [opt.value]: opt.label
    }), {});
const offlineOptions = [
    {
        label: "At check-in or check-out",
        value: "true"
    },
    {
        label: "At time of booking",
        value: "false"
    }
];
const chargeTypeOptions = [
    {
        label: "Per Group",
        value: "PER_GROUP"
    },
    {
        label: "Per Person",
        value: "PER_PERSON"
    }
];
const feeUnitTypeOptions = [
    {
        label: "Per kilowatt hour",
        value: "PER_KILOWATT_HOUR"
    },
    {
        label: "Per liter",
        value: "PER_LITER"
    },
    {
        label: "Per cubic meter",
        value: "PER_CUBIC_METER"
    }
];
const mapLabelFeeUnitType = feeUnitTypeOptions.reduce((map, opt)=>_object_spread_props(_object_spread({}, map), {
        [opt.value]: opt.label
    }), {});
