"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "PauseIcon", {
    enumerable: true,
    get: function() {
        return PauseIcon;
    }
});
const _pauseiconsvg = require("../../../assets/pause-icon.svg");
const _iconMaker = require("./iconMaker");
const PauseIcon = (0, _iconMaker.iconMaker)(_pauseiconsvg.ReactComponent, "PauseIcon");
