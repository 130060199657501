"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _cogiconsvg = require("../../../assets/cog-icon.svg");
const _iconMaker = require("./iconMaker");
const CogIcon = (0, _iconMaker.iconMaker)(_cogiconsvg.ReactComponent, "CogIcon");
const _default = CogIcon;
