"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useWatchPrice", {
    enumerable: true,
    get: function() {
        return useWatchPrice;
    }
});
const _frontend = require("../../../../frontend/src/index");
const _react = require("react");
function useWatchPrice(onPriceChange) {
    const websocket = (0, _react.useContext)(_frontend.WebSocketContext);
    (0, _react.useEffect)(()=>{
        websocket === null || websocket === void 0 ? void 0 : websocket.on("price", onPriceChange);
        return ()=>{
            websocket === null || websocket === void 0 ? void 0 : websocket.off("price", onPriceChange);
        };
    }, [
        websocket,
        onPriceChange
    ]);
}
