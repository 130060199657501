"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useCheckInOutTimes", {
    enumerable: true,
    get: function() {
        return useCheckInOutTimes;
    }
});
const _react = require("react");
const useCheckInOutTimes = ()=>{
    return (0, _react.useMemo)(()=>{
        const options = [];
        let i = 0;
        while(i < 24){
            let period = "AM";
            if (i > 11) {
                period = "PM";
            }
            let timeNumber = i;
            if (i === 0) {
                timeNumber = 12;
            } else if (i > 12) {
                timeNumber = i - 12;
            }
            options.push({
                value: i,
                label: `At ${timeNumber}${period}`
            });
            i += 1;
        }
        return options;
    }, []);
};
