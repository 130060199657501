"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ImportIcon", {
    enumerable: true,
    get: function() {
        return ImportIcon;
    }
});
const _importiconsvg = require("../../../assets/import-icon.svg");
const _iconMaker = require("./iconMaker");
const ImportIcon = (0, _iconMaker.iconMaker)(_importiconsvg.ReactComponent, "ImportIcon");
