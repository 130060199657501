"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _frontend = require("../../../../../../frontend/src/index");
const _reactquery = require("@tanstack/react-query");
const _react = require("react");
const useBilling = ()=>{
    const billingService = (0, _frontend.useBillingService)();
    const queryFn = (0, _react.useCallback)(()=>{
        return billingService.fetchBilling();
    }, [
        billingService
    ]);
    return (0, _reactquery.useQuery)({
        queryKey: [
            "billing"
        ],
        queryFn
    });
};
const _default = useBilling;
