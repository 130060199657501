"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
function useFilteredListings(listings, query) {
    return (0, _react.useMemo)(()=>{
        const filteredQuery = query.toLowerCase().trim();
        return listings.filter((listing)=>{
            let matchesQuery = true;
            if (filteredQuery !== "") {
                if (listing.airbnbName.toLowerCase().indexOf(filteredQuery) === -1 && (!listing.nickname || listing.nickname.toLowerCase().indexOf(filteredQuery) === -1)) {
                    matchesQuery = false;
                }
            }
            return matchesQuery;
        });
    }, [
        listings,
        query
    ]);
}
const _default = useFilteredListings;
