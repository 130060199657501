"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const airbnbOAuthUrlMaker = (returnUrl, state)=>{
    return `https://www.airbnb.com/oauth2/auth?client_id=${AIRBNB_CLIENT_ID}&redirect_uri=${encodeURIComponent(returnUrl !== null && returnUrl !== void 0 ? returnUrl : AIRBNB_REDIRECT_URL)}&scope=${AIRBNB_SCOPE}&state=${state ? encodeURIComponent(state) : ""}`;
};
const _default = airbnbOAuthUrlMaker;
