"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useGooglePlaces", {
    enumerable: true,
    get: function() {
        return useGooglePlaces;
    }
});
const _frontend = require("../../../../frontend/src/index");
const _react = require("react");
const useGooglePlaces = ()=>{
    const autocompleteServiceRef = (0, _react.useRef)();
    const placeServiceRef = (0, _react.useRef)();
    // load the scripts on mounted
    // the api should enable the Javascript API
    (0, _frontend.useLoadScript)(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&loading=async&libraries=places&callback=initMap`, {});
    const init = (0, _react.useCallback)(()=>{
        const { google: google1 } = window;
        const { places } = google1.maps;
        autocompleteServiceRef.current = new places.AutocompleteService();
        placeServiceRef.current = new places.PlacesService(document.createElement("div"));
    }, []);
    // init the map
    (0, _react.useEffect)(()=>{
        const { google: google1 } = window;
        if (!google1) {
            window.initMap = init;
        } else {
            init();
        }
        return ()=>{
            if (window.initMap) {
                delete window.initMap;
            }
        };
    }, [
        init
    ]);
    return {
        fetchPredictions: (0, _frontend.useDebounceFn)(async (input, callback)=>{
            if (input && autocompleteServiceRef.current) {
                autocompleteServiceRef.current.getPlacePredictions({
                    input,
                    language: "en"
                }, (result, status)=>{
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        callback(result !== null && result !== void 0 ? result : []);
                    }
                });
            }
        }, 350),
        fetchPlaceDetails: (placeId, callback)=>{
            var _placeServiceRef_current;
            (_placeServiceRef_current = placeServiceRef.current) === null || _placeServiceRef_current === void 0 ? void 0 : _placeServiceRef_current.getDetails({
                placeId
            }, (place, status)=>{
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    if (place) {
                        var _geometry_location, _geometry_location1;
                        const { geometry, address_components: addressComponents } = place;
                        if (!addressComponents) {
                            callback(null);
                            return;
                        }
                        var _place_formatted_address;
                        const details = {
                            address: (_place_formatted_address = place.formatted_address) !== null && _place_formatted_address !== void 0 ? _place_formatted_address : "",
                            region: "",
                            streetNumber: "",
                            streetName: "",
                            city: "",
                            country: "",
                            countryCode: "",
                            latitude: geometry === null || geometry === void 0 ? void 0 : (_geometry_location = geometry.location) === null || _geometry_location === void 0 ? void 0 : _geometry_location.lat(),
                            longitude: geometry === null || geometry === void 0 ? void 0 : (_geometry_location1 = geometry.location) === null || _geometry_location1 === void 0 ? void 0 : _geometry_location1.lng()
                        };
                        addressComponents === null || addressComponents === void 0 ? void 0 : addressComponents.forEach((component)=>{
                            const { types } = component;
                            if (types.includes("administrative_area_level_1")) {
                                details.region = component.long_name;
                            }
                            if (types.includes("street_number")) {
                                details.streetNumber = component.long_name;
                            }
                            if (types.includes("route")) {
                                details.streetName = component.long_name;
                            }
                            if (types.includes("country")) {
                                details.country = component.long_name;
                                details.countryCode = component.short_name;
                            }
                            if (types.includes("postal_code")) {
                                details.zipcode = component.long_name;
                            }
                            if (types.includes("locality")) {
                                details.city = component.long_name;
                            }
                            if (types.includes("sublocality_level_1")) {
                                details.subLocality = component.long_name;
                            }
                            if (types.includes("administrative_area_level_2")) {
                                details.administrativeAreaLevel2 = component.long_name;
                            }
                            if (types.includes("subpremise")) {
                                details.apartment = component.long_name;
                            }
                        });
                        // some countries are hard to find out where the city is
                        if (!details.city) {
                            details.city = details.subLocality || details.administrativeAreaLevel2 || "";
                        }
                        callback(details);
                    } else {
                        callback(null);
                    }
                }
            });
        },
        searchForPlace: (query, callback)=>{
            var _placeServiceRef_current;
            (_placeServiceRef_current = placeServiceRef.current) === null || _placeServiceRef_current === void 0 ? void 0 : _placeServiceRef_current.findPlaceFromQuery({
                query,
                fields: [
                    "formatted_address",
                    "address_components"
                ]
            }, (result, status)=>{
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    callback(result);
                }
            });
        }
    };
};
