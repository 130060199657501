"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "SubUser", {
    enumerable: true,
    get: function() {
        return SubUser;
    }
});
const _utilspermission = require("../../../core/src/shared/utils/utils.permission");
const _user = require("./user");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class SubUser {
    constructor(raw){
        _define_property(this, "_id", void 0);
        _define_property(this, "username", void 0);
        _define_property(this, "firstName", void 0);
        _define_property(this, "lastName", void 0);
        _define_property(this, "fullName", void 0);
        _define_property(this, "permissions", void 0);
        _define_property(this, "listingGroupIDs", void 0);
        _define_property(this, "role", void 0);
        this._id = raw._id;
        this.username = raw.username;
        this.firstName = raw.firstName;
        this.lastName = raw.lastName;
        this.permissions = raw.permissions;
        this.listingGroupIDs = raw.listingGroupIDs;
        this.fullName = (0, _user.getFullName)(raw);
        this.role = this.permissions ? (0, _utilspermission.getRoleByPermissions)(this.permissions) : undefined;
    }
}
