"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const _UserContext = require("../../contexts/UserContext");
const useListing = (listingID)=>{
    const { listings } = (0, _react.useContext)(_UserContext.UserContext);
    return (0, _react.useMemo)(()=>{
        if (!listingID) {
            return undefined;
        }
        return listings.find((elem)=>elem._id === listingID);
    }, [
        listings,
        listingID
    ]);
};
const _default = useListing;
