"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _base = require("../base");
class AccountService extends _base.Base {
    async forceUpdate(id) {
        await this.http.post("/forceUpdate", {
            _id: id
        });
    }
    async deleteAccount(id) {
        await this.http.post("/deleteAccount", {
            accountID: id
        });
    }
    async connectAirbnbAccount(code) {
        const { data } = await this.http.post("/connectAirbnbAccount", {
            code
        });
        return data;
    }
    async getChannelConnectorURL(accountID) {
        const { data } = await this.http.post("/getChannelConnectorURL", {
            accountID
        });
        return data.url;
    }
}
const _default = AccountService;
