"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    ZINDEX_DRAWER: function() {
        return ZINDEX_DRAWER;
    },
    ZINDEX_MODAL: function() {
        return ZINDEX_MODAL;
    },
    ZINDEX_POPOVER: function() {
        return ZINDEX_POPOVER;
    },
    ZINDEX_TOAST: function() {
        return ZINDEX_TOAST;
    }
});
const ZINDEX_MODAL = 1300;
const ZINDEX_DRAWER = 1200;
const ZINDEX_POPOVER = 1400;
const ZINDEX_TOAST = 1500;
