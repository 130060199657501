"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _file = require("../../utils/file");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
class MessageRuleService {
    async deleteImagesFromMessageRule({ messageRuleID, keys }, config) {
        const url = "/deleteImagesMessageRule";
        const { data } = await this.http.post(url, {
            messageRuleID,
            keys
        }, config);
        return data;
    }
    async addImageMessageRule(_param, config) {
        var { messageRuleID } = _param, rest = _object_without_properties(_param, [
            "messageRuleID"
        ]);
        const url = "/addImageMessageRule";
        const formData = (0, _file.generateImageUploadFormData)(rest);
        formData.append("messageRuleID", messageRuleID);
        const { data } = await this.http.post(url, formData, _object_spread({
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, config));
        return data;
    }
    async getMessageRules() {
        const url = "/getMessageRules";
        const { data } = await this.http.get(url);
        return data;
    }
    async previewMessage(language, value) {
        const url = "/previewMessageRule";
        const { data } = await this.http.post(url, {
            preferredLocale: language,
            messages: {
                default: value,
                [language]: value
            }
        });
        return data.message;
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
const _default = MessageRuleService;
