"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "defaultPageSize", {
    enumerable: true,
    get: function() {
        return defaultPageSize;
    }
});
const defaultPageSize = 10;
