"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "CalendarDayIcon", {
    enumerable: true,
    get: function() {
        return CalendarDayIcon;
    }
});
const _calendardaysvg = require("../../../assets/calendar-day.svg");
const _iconMaker = require("./iconMaker");
const CalendarDayIcon = (0, _iconMaker.iconMaker)(_calendardaysvg.ReactComponent, "CalendarDayIcon");
