"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "convertOptionsToMap", {
    enumerable: true,
    get: function() {
        return convertOptionsToMap;
    }
});
function convertOptionsToMap(options) {
    return options.reduce((result, option)=>{
        result[option.value] = option.label;
        return result;
    }, {});
}
