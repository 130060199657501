"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _refreshsvg = require("../../../assets/refresh.svg");
const _iconMaker = require("./iconMaker");
const RefreshIcon = (0, _iconMaker.iconMaker)(_refreshsvg.ReactComponent, "RefreshIcon");
const _default = RefreshIcon;
