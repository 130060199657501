"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
function useStatusLabel(status) {
    return (0, _react.useMemo)(()=>{
        const labelsMapping = {
            listed: "Listed",
            pending: "Pending",
            unlisted: "Unlisted",
            "not found": "Not Found"
        };
        return status ? labelsMapping[status] : "";
    }, [
        status
    ]);
}
const _default = useStatusLabel;
