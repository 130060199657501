"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useOutsideAlerter", {
    enumerable: true,
    get: function() {
        return useOutsideAlerter;
    }
});
const _react = require("react");
function useOutsideAlerter(ref, onClickOutside, approvedList = []) {
    (0, _react.useEffect)(()=>{
        /**
         * Alert if clicked on outside of element
         */ function handleClickOutside(event) {
            const cacheEvent = event.target;
            if (ref.current && cacheEvent instanceof HTMLElement && !ref.current.contains(cacheEvent)) {
                // Element found mean the pointer is outside the Calendar
                const containsApprovedList = approvedList.length && approvedList.some((elem)=>cacheEvent.querySelector(elem) !== null);
                if (containsApprovedList) {
                    onClickOutside();
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return ()=>{
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [
        ref,
        approvedList,
        onClickOutside
    ]);
}
