"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ImageIcon", {
    enumerable: true,
    get: function() {
        return ImageIcon;
    }
});
const _imageiconsvg = require("../../../assets/image-icon.svg");
const _iconMaker = require("./iconMaker");
const ImageIcon = (0, _iconMaker.iconMaker)(_imageiconsvg.ReactComponent, "ImageIcon");
