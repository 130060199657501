"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    channelsSupportSendingPhotos: function() {
        return channelsSupportSendingPhotos;
    },
    maxPhotoSize: function() {
        return maxPhotoSize;
    }
});
const maxPhotoSize = {
    width: 1080,
    height: 1080
};
const channelsSupportSendingPhotos = [
    "Airbnb",
    "Booking",
    "HomeAway",
    "Houfy",
    "internal"
];
