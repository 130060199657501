"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _prohibitediconsvg = require("../../../assets/prohibited-icon.svg");
const _iconMaker = require("./iconMaker");
const ProhibitedIcon = (0, _iconMaker.iconMaker)(_prohibitediconsvg.ReactComponent, "ProhibitedIcon");
const _default = ProhibitedIcon;
