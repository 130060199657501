"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "LinkIcon", {
    enumerable: true,
    get: function() {
        return LinkIcon;
    }
});
const _linkiconsvg = require("../../../assets/link-icon.svg");
const _iconMaker = require("./iconMaker");
const LinkIcon = (0, _iconMaker.iconMaker)(_linkiconsvg.ReactComponent, "LinkIcon");
