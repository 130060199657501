"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "replacePathParams", {
    enumerable: true,
    get: function() {
        return replacePathParams;
    }
});
function replacePathParams(url, params) {
    if (!params) {
        return url;
    }
    return url.replace(/\/:([^/?]+)\??/g, (_, key)=>{
        if (params[key] !== undefined) {
            return `/${encodeURIComponent(params[key])}`;
        }
        return "";
    });
}
