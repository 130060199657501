"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _pencil2svg = require("../../../assets/pencil2.svg");
const _iconMaker = require("./iconMaker");
const PencilIcon = (0, _iconMaker.iconMaker)(_pencil2svg.ReactComponent, "PencilIcon");
const _default = PencilIcon;
