"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const useMounted = ()=>{
    const isMounted = (0, _react.useRef)(false);
    (0, _react.useEffect)(()=>{
        isMounted.current = true;
        return ()=>{
            isMounted.current = false;
        };
    }, []);
    // Don't return isMounted.current which won't work correctly
    // if you use it in a function such as `debounce`
    return isMounted;
};
const _default = useMounted;
