"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _frontend = require("../../../../../frontend/src/index");
const _reactquery = require("@tanstack/react-query");
const _react = require("react");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
const useListingObjectFromChannel = ({ listingID, channel })=>{
    const listingService = (0, _frontend.useListingService)();
    const { data, isLoading } = (0, _reactquery.useQuery)({
        queryKey: [
            "listing",
            listingID,
            channel
        ],
        enabled: !!listingID && !!channel,
        queryFn: (0, _react.useCallback)(async ()=>{
            if (!listingID || !channel) {
                return Promise.resolve(null);
            }
            return listingService.post("/listingObjFromChannel", {
                listingID,
                channel
            });
        }, [
            listingID,
            channel,
            listingService
        ])
    });
    const listing = (0, _react.useMemo)(()=>{
        if (!data) {
            return undefined;
        }
        const { userID } = data, rest = _object_without_properties(data, [
            "userID"
        ]);
        const listing = _object_spread({}, rest);
        return listing;
    }, [
        data
    ]);
    return {
        listing,
        isLoading
    };
};
const _default = useListingObjectFromChannel;
