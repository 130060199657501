"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getLabelAmountType: function() {
        return getLabelAmountType;
    },
    getLabelTaxType: function() {
        return getLabelTaxType;
    },
    optionsAmountTaxType: function() {
        return optionsAmountTaxType;
    },
    optionsTaxType: function() {
        return optionsTaxType;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const optionsTaxType = [
    {
        label: "Hotel tax",
        value: "pass_through_hotel_tax"
    },
    {
        label: "Lodging tax",
        value: "pass_through_lodging_tax"
    },
    {
        label: "Room tax",
        value: "pass_through_room_tax"
    },
    {
        label: "Tourist tax",
        value: "pass_through_tourist_tax"
    },
    {
        label: "Transient occupancy tax",
        value: "pass_through_transient_occupancy_tax"
    },
    {
        label: "Sales tax",
        value: "pass_through_sales_tax"
    },
    {
        label: "VAT/GST",
        value: "pass_through_vat_gst"
    },
    {
        label: "Tourism Assessment/Fee",
        value: "pass_through_tourism_assessment_fee"
    }
];
const mapLabelTaxType = optionsTaxType.reduce((map, opt)=>_object_spread_props(_object_spread({}, map), {
        [opt.value]: opt.label
    }), {});
function getLabelTaxType(value) {
    return mapLabelTaxType[value];
}
const optionsAmountTaxType = [
    {
        label: "% per Reservation",
        value: "percent_per_reservation"
    },
    {
        label: "Amount per Guest",
        value: "flat_per_guest"
    },
    {
        label: "Amount per Guest per Night",
        value: "flat_per_guest_per_night"
    }
];
const mapLabelAmountTaxType = optionsAmountTaxType.reduce((map, opt)=>_object_spread_props(_object_spread({}, map), {
        [opt.value]: opt.label
    }), {});
function getLabelAmountType(value) {
    return mapLabelAmountTaxType[value];
}
