"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    generateMapRuleTypes: function() {
        return generateMapRuleTypes;
    },
    pricingRuleTypeOptions: function() {
        return pricingRuleTypeOptions;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const pricingRuleTypeOptions = [
    {
        label: "Seasonal rule adjustment",
        value: "SEASONAL_ADJUSTMENT"
    },
    {
        label: "Long-term stay adjustment",
        value: "STAYED_AT_LEAST_X_DAYS"
    },
    {
        label: "Last-minute discount",
        value: "BOOKED_WITHIN_AT_MOST_X_DAYS"
    },
    {
        label: "Booking ahead discount",
        value: "BOOKED_BEYOND_AT_LEAST_X_DAYS"
    }
];
const generateMapRuleTypes = ()=>{
    return pricingRuleTypeOptions.reduce((result, option)=>{
        return _object_spread_props(_object_spread({}, result), {
            [option.value]: option.label
        });
    }, {});
};
