"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    generateListingOptions: function() {
        return generateListingOptions;
    },
    generateNewListings: function() {
        return generateNewListings;
    }
});
function generateListingOptions(listings, listingsInListingGroup) {
    const options = listings.reduce((result, listing)=>{
        if (listingsInListingGroup === null || listingsInListingGroup === void 0 ? void 0 : listingsInListingGroup.find((listingInGroup)=>{
            return listingInGroup._id === listing._id;
        })) {
            return result;
        }
        result.push({
            value: listing._id,
            label: listing.name,
            listing
        });
        return result;
    }, []);
    options.sort((a, b)=>{
        let labelA = a.label;
        let labelB = b.label;
        // Make sure the label is defined
        if (!labelA || !labelB) {
            return 0;
        }
        labelA = labelA.toLowerCase();
        labelB = labelB.toLowerCase();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });
    return options;
}
function generateNewListings(listings, option, listingsInListingGroup) {
    const listing = listings.find((listing)=>{
        return option.value === listing._id;
    });
    if (!listing) {
        return;
    }
    const newListings = listingsInListingGroup ? [
        ...listingsInListingGroup
    ] : [];
    if (newListings.some((newListing)=>listing._id === newListing._id)) {
        return;
    }
    newListings.push(listing);
    newListings.sort((a, b)=>{
        let nameA = a.airbnbName.toLowerCase();
        let nameB = b.airbnbName.toLowerCase();
        if (a.nickname) {
            nameA = a.nickname.toLowerCase();
        }
        if (b.nickname) {
            nameB = b.nickname.toLowerCase();
        }
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return newListings;
}
