"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useListingsByAccountID", {
    enumerable: true,
    get: function() {
        return useListingsByAccountID;
    }
});
const _react = require("react");
const _contexts = require("../../contexts");
const useListingsByAccountID = (accountID)=>{
    const { listings, accounts } = (0, _react.useContext)(_contexts.UserContext);
    return (0, _react.useMemo)(()=>{
        if (!accountID) {
            return [];
        }
        const account = accounts.find((elem)=>elem._id === accountID);
        if (!account || account.type === "August" || account.type === "Seam") {
            // invalid account
            return [];
        }
        if (listings.length) {
            return listings.filter((elem)=>{
                var _elem_channels_account_type;
                return ((_elem_channels_account_type = elem.channels[account.type]) === null || _elem_channels_account_type === void 0 ? void 0 : _elem_channels_account_type.accountID) === accountID;
            });
        }
        return [];
    }, [
        accountID,
        accounts,
        listings
    ]);
};
