"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useFetchListingID", {
    enumerable: true,
    get: function() {
        return useFetchListingID;
    }
});
const _reactquery = require("@tanstack/react-query");
const _react = require("react");
const _listing = require("../../../models/listing");
const _useListingService = require("../useListingService");
const useFetchListingID = (listingID, noCache = false)=>{
    const listingService = (0, _useListingService.useListingService)();
    return (0, _reactquery.useQuery)({
        retry: 0,
        gcTime: noCache ? 0 : undefined,
        queryKey: [
            "useFetchListingID",
            listingID !== null && listingID !== void 0 ? listingID : ""
        ],
        queryFn: (0, _react.useCallback)(async ()=>{
            if (!listingID) {
                return Promise.resolve(null);
            }
            const data = await listingService.get("/getListing/:listingID", {
                listingID
            });
            return (0, _listing.sanitizeListing)(data);
        }, [
            listingID,
            listingService
        ])
    });
};
