"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useWatchMessageRule", {
    enumerable: true,
    get: function() {
        return useWatchMessageRule;
    }
});
const _frontend = require("../../../../frontend/src/index");
const _react = require("react");
const _messageRule = require("../services/messageRule");
const useWatchMessageRule = (payload, loadOnMount = false)=>{
    const ws = (0, _react.useContext)(_frontend.WebSocketContext);
    const [messageRules, setMessageRules] = (0, _react.useState)();
    const fetch = (0, _react.useCallback)(async (payload)=>{
        const messageRules = await (0, _messageRule.fetchMessageRules)(payload);
        if (messageRules) {
            setMessageRules(messageRules);
        }
    }, []);
    (0, _react.useEffect)(()=>{
        if (loadOnMount) {
            fetch(payload);
        }
    }, [
        loadOnMount,
        payload,
        fetch
    ]);
    (0, _react.useEffect)(()=>{
        ws === null || ws === void 0 ? void 0 : ws.on("messageRule", syncMessageRule);
        return ()=>{
            ws === null || ws === void 0 ? void 0 : ws.off("messageRule", syncMessageRule);
        };
    }, [
        ws
    ]);
    function syncMessageRule(payload) {
        setMessageRules((prev)=>{
            if (!prev) {
                return prev;
            }
            const rules = (0, _frontend.updateListWS)(prev, payload);
            return rules;
        });
    }
    return [
        messageRules,
        fetch
    ];
};
