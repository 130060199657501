"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    array2Object: function() {
        return array2Object;
    },
    arrayToObject: function() {
        return arrayToObject;
    },
    checkIsMacDevice: function() {
        return checkIsMacDevice;
    },
    formatPhoneNumber: function() {
        return formatPhoneNumber;
    },
    getNewUIFlag: function() {
        return getNewUIFlag;
    },
    object2Array: function() {
        return object2Array;
    },
    setNewUIFlag: function() {
        return setNewUIFlag;
    },
    takeFirstLetter: function() {
        return takeFirstLetter;
    }
});
const _storage = require("./storage");
function formatPhoneNumber(str) {
    const cleaned = `${str}`.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        // Remove the matched extension code
        // Change this to format for any country code.
        const intlCode = match[1] ? "+1 " : "";
        return [
            intlCode,
            "(",
            match[2],
            ") ",
            match[3],
            "-",
            match[4]
        ].join("");
    }
    return `${str}`;
}
function object2Array(obj) {
    return Object.keys(obj).map((key)=>obj[key]);
}
function array2Object(arr) {
    return arr.reduce((result, item)=>{
        result[item._id] = item;
        return result;
    }, {});
}
function arrayToObject(arr, key) {
    return arr.reduce((obj, item)=>{
        const k = item[key];
        obj[k] = item;
        return obj;
    // @ts-ignore 5.x can fix this issue
    }, {});
}
function checkIsMacDevice() {
    return window.navigator.userAgent.toUpperCase().indexOf("MAC") >= 0;
}
function takeFirstLetter(name) {
    const parts = name.split(" ");
    let firstLetter = "";
    if (parts.length >= 1) {
        firstLetter = parts[0].charAt(0);
    }
    if (parts.length >= 2) {
        firstLetter += parts[1].charAt(0);
    }
    return firstLetter.toUpperCase();
}
const LS_NEW_UI_KEY = "new-ui";
const setNewUIFlag = (value)=>{
    (0, _storage.setLocal)(LS_NEW_UI_KEY, value);
};
const getNewUIFlag = ()=>(0, _storage.getLocal)(LS_NEW_UI_KEY);
