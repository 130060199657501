"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _externalsvg = require("../../../assets/external.svg");
const _iconMaker = require("./iconMaker");
const ExternalIcon = (0, _iconMaker.iconMaker)(_externalsvg.ReactComponent, "ExternalIcon");
const _default = ExternalIcon;
