"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useLatest", {
    enumerable: true,
    get: function() {
        return useLatest;
    }
});
const _react = require("react");
const useLatest = (value)=>{
    const ref = (0, _react.useRef)(value);
    (0, _react.useEffect)(()=>{
        ref.current = value;
    }, [
        value
    ]);
    return ref;
};
