"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return buildChannels;
    }
});
function buildChannels(listing = {}) {
    return listing.channels ? Object.keys(listing.channels) : [];
}
