"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _copysvg = require("../../../assets/copy.svg");
const _iconMaker = require("./iconMaker");
const CopyIcon = (0, _iconMaker.iconMaker)(_copysvg.ReactComponent, "Copy");
const _default = CopyIcon;
