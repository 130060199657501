"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "validatePrices", {
    enumerable: true,
    get: function() {
        return validatePrices;
    }
});
const _constant = require("../../../../../core/src/constant");
function validatePrices(prices, listing) {
    let errors = {};
    // Make sure prices are higher than minimum
    errors = Object.keys(prices).reduce((result, channel)=>{
        if (channel === "all" || _constant.providerChannels.includes(channel)) {
            if (!prices[channel] || errors.all) {
                return result;
            }
            const { priceOverride } = prices[channel];
            if (priceOverride && Number(priceOverride) < 0) {
                result[channel] = "Price should be greater than 0.";
            }
            if (priceOverride && listing.minPrice && Number(priceOverride) < listing.minPrice) {
                result[channel] = "Price is below the minimum price. You can change your minimum price from the listing settings.";
            }
        }
        return result;
    }, errors);
    return errors;
}
