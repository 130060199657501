"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
const useAbortController = (...deps)=>{
    const abortRef = (0, _react.useRef)();
    (0, _react.useEffect)(()=>{
        abortRef.current = new AbortController();
        return ()=>{
            var _abortRef_current;
            (_abortRef_current = abortRef.current) === null || _abortRef_current === void 0 ? void 0 : _abortRef_current.abort();
        };
    }, deps);
    return abortRef;
};
const _default = useAbortController;
