"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    filterVisibleListings: function() {
        return filterVisibleListings;
    },
    parseListingToSavePayload: function() {
        return parseListingToSavePayload;
    }
});
const _constant = require("../../../core/src/constant");
function filterVisibleListings(listings) {
    return listings.reduce((result, listing)=>{
        if (listing.hidden) {
            return result;
        }
        result.push(listing);
        return result;
    }, []);
}
function parseListingToSavePayload(listing) {
    const { airbnbName, nickname = "", priceSource, basePrice, minPrice, minNights, pricingEnabled, syncAvailability, calendars, bookingWindow, hidden, calendarExportCode, listingEnabled } = listing;
    const payload = {
        airbnbName,
        nickname,
        priceSource,
        basePrice,
        minPrice,
        listingEnabled: listingEnabled !== false,
        pricingEnabled,
        syncAvailability,
        calendars,
        calendarExportCode: calendarExportCode !== null && calendarExportCode !== void 0 ? calendarExportCode : "",
        minNights,
        bookingWindow,
        hidden: hidden && !listing.listingEnabled // If listing is enabled then it shouldn't be hidden
    };
    if (!priceSource) {
        payload.priceSource = "Amount";
    }
    // If the listing has pricing disabled but availability enabled, then the price source should be Beyond Pricing
    if (!pricingEnabled && syncAvailability) {
        payload.priceSource = "Beyond Pricing";
    }
    if (!basePrice) {
        payload.basePrice = 0;
    }
    if (!Number.isNaN(Number(minNights))) {
        payload.minNights = minNights;
    } else {
        payload.minNights = 1;
    }
    if (!bookingWindow && bookingWindow !== 0) {
        payload.bookingWindow = _constant.yearInDays;
    }
    return payload;
}
