"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    CALENDAR_SLOT_WIDTH: function() {
        return CALENDAR_SLOT_WIDTH;
    },
    CALENDAR_SLOT_WIDTH_MOBILE: function() {
        return CALENDAR_SLOT_WIDTH_MOBILE;
    },
    CALENDAR_SLOT_WIDTH_NATIVE_BASE: function() {
        return CALENDAR_SLOT_WIDTH_NATIVE_BASE;
    },
    INBOX_PATH: function() {
        return INBOX_PATH;
    },
    INBOX_URL: function() {
        return INBOX_URL;
    },
    MAX_NUMBER_OF_NEXT_YEARS: function() {
        return MAX_NUMBER_OF_NEXT_YEARS;
    },
    MESSAGING_PATH: function() {
        return MESSAGING_PATH;
    },
    MULTICALENDAR_PATH: function() {
        return MULTICALENDAR_PATH;
    },
    PRICING_PATH: function() {
        return PRICING_PATH;
    },
    REGEX_EMAIL: function() {
        return REGEX_EMAIL;
    },
    REGEX_URL: function() {
        return REGEX_URL;
    },
    base64ImagePrefix: function() {
        return base64ImagePrefix;
    }
});
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
const CALENDAR_SLOT_WIDTH = 80;
const CALENDAR_SLOT_WIDTH_NATIVE_BASE = 74;
const CALENDAR_SLOT_WIDTH_MOBILE = 80;
const REGEX_URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/i;
const base64ImagePrefix = "data:image/png;base64,";
const MULTICALENDAR_PATH = "/";
const INBOX_PATH = "/inbox/:reservationID?";
const MESSAGING_PATH = "/messaging/:listingID/:listingGroupID?";
const PRICING_PATH = "/pricing/:listingID";
const INBOX_URL = "/inbox";
const MAX_NUMBER_OF_NEXT_YEARS = 2;
