"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    CLASSNAME_WRAPPER: function() {
        return CLASSNAME_WRAPPER;
    },
    NUMBER_OF_DATES_FROM_CURRENT_DATE: function() {
        return NUMBER_OF_DATES_FROM_CURRENT_DATE;
    },
    useMultiCalendarScroll: function() {
        return useMultiCalendarScroll;
    }
});
const _frontend = require("../../../../frontend/src/index");
const _datefns = require("date-fns");
const _lodash = require("lodash");
const _react = require("react");
const _constant = require("../constant");
const _utils = require("../utils");
const NUMBER_OF_DATES_FROM_CURRENT_DATE = 15;
const CLASSNAME_WRAPPER = "full-calendar-infinite-scroll-wrapper";
const defaultOptions = {
    stepDates: 20,
    numberOfDatesFromCurrentDate: 15,
    slotMinWidth: 80
};
function getScroller(calendarDom) {
    return calendarDom.querySelector("tbody td:nth-child(3) .fc-scroller");
}
function useMultiCalendarScroll({ calendarRef, scrollOptions, onChangeCalendar, firstLoad, onChangeDateView }) {
    const { stepDates = defaultOptions.stepDates, numberOfDatesFromCurrentDate = defaultOptions.numberOfDatesFromCurrentDate, slotMinWidth = defaultOptions.slotMinWidth } = scrollOptions;
    const LATENCY_SCROLL = slotMinWidth * 3; // pixel unit, latency to scroll to start or end
    const [days, setDays] = (0, _react.useState)(0);
    const [calendarDom, setCalendarDom] = (0, _react.useState)();
    const scrollerClickedRef = (0, _react.useRef)(false);
    const scrollTimeoutIdRef = (0, _react.useRef)();
    const getCalendarApi = (0, _react.useCallback)(function getCalendarApi() {
        const { current } = calendarRef;
        return current === null || current === void 0 ? void 0 : current.getApi();
    }, [
        calendarRef
    ]);
    const firstLoadTimeout = (0, _frontend.useTimeout)(()=>{
        const calendarApi = getCalendarApi();
        if (calendarApi === null || calendarApi === void 0 ? void 0 : calendarApi.view) {
            const { activeStart, activeEnd } = calendarApi.view;
            firstLoad === null || firstLoad === void 0 ? void 0 : firstLoad((0, _datefns.subDays)(activeStart, stepDates + 1), (0, _datefns.addDays)(activeEnd, stepDates + 1));
        }
    }, 500);
    const handleChangeCalendar = (0, _react.useCallback)((currentStartDate, currentEndDate, distanceDays)=>{
        const activeStartDate = (0, _datefns.addDays)(currentStartDate, distanceDays);
        const activeEndDate = (0, _datefns.addDays)(currentEndDate, distanceDays);
        onChangeCalendar === null || onChangeCalendar === void 0 ? void 0 : onChangeCalendar(activeStartDate, activeEndDate, distanceDays);
    }, [
        onChangeCalendar
    ]);
    const calendarMove = (0, _react.useCallback)((steps)=>{
        if (!calendarDom) {
            return;
        }
        const calendarApi = getCalendarApi();
        if (calendarApi === null || calendarApi === void 0 ? void 0 : calendarApi.view) {
            const { activeStart, activeEnd } = calendarApi.view;
            const scroller = getScroller(calendarDom);
            if (!scroller) {
                return;
            }
            const { scrollLeft } = scroller;
            const scrollDaysBackToCurrentDateView = Math.floor((scrollLeft - steps * slotMinWidth) / slotMinWidth);
            const remain = scrollLeft - steps * slotMinWidth - scrollDaysBackToCurrentDateView * slotMinWidth;
            calendarApi.batchRendering(()=>{
                scrollTimeoutIdRef.current = window.setTimeout(()=>{
                    const scroller = getScroller(calendarDom);
                    if (!scroller) {
                        return;
                    }
                    const { scrollLeft, clientWidth, scrollWidth } = scroller;
                    const nearlyStart = scrollLeft - LATENCY_SCROLL < 0;
                    const nearlyEnd = scrollWidth - scrollLeft < clientWidth + LATENCY_SCROLL;
                    if (nearlyStart || nearlyEnd) {
                        calendarApi.gotoDate((0, _datefns.addDays)(activeStart, steps));
                        if (!scrollerClickedRef.current) {
                            calendarApi.scrollToTime({
                                days: scrollDaysBackToCurrentDateView
                            });
                            scroller.scrollLeft += remain;
                        } else {
                            // Make scroll move to load more data when user keep click on scrollbar
                            scroller.scrollLeft += remain > 0 ? -1 : 1;
                        }
                    }
                }, 0);
            });
            handleChangeCalendar(activeStart, activeEnd, steps);
        }
    }, [
        LATENCY_SCROLL,
        calendarDom,
        getCalendarApi,
        handleChangeCalendar,
        slotMinWidth
    ]);
    const throttleCalendarMove = (0, _react.useMemo)(()=>(0, _lodash.throttle)(calendarMove, 1000), [
        calendarMove
    ]);
    const navigateCalendar = (0, _react.useCallback)((event)=>{
        if (event.target instanceof HTMLDivElement) {
            const { scrollWidth, scrollLeft, clientWidth } = event.target;
            const nearlyStart = scrollLeft - LATENCY_SCROLL < 0;
            if (nearlyStart) {
                throttleCalendarMove(-stepDates);
            }
            const nearlyEnd = scrollWidth - scrollLeft < clientWidth + LATENCY_SCROLL;
            if (nearlyEnd) {
                throttleCalendarMove(stepDates);
            }
        }
    }, [
        LATENCY_SCROLL,
        stepDates,
        throttleCalendarMove
    ]);
    const onDateChangeAlongWithScroll = (0, _react.useCallback)((event)=>{
        if (event.target instanceof HTMLDivElement) {
            const { scrollLeft } = event.target;
            const calendarApi = getCalendarApi();
            if (calendarApi) {
                const { activeStart } = calendarApi.view;
                const scrollDaysFromActiveStart = Math.ceil(scrollLeft / slotMinWidth);
                onChangeDateView === null || onChangeDateView === void 0 ? void 0 : onChangeDateView((0, _datefns.addDays)(activeStart, scrollDaysFromActiveStart));
            }
        }
    }, [
        getCalendarApi,
        onChangeDateView,
        slotMinWidth
    ]);
    const handleMoveToDate = (0, _react.useCallback)((date)=>{
        const calendarApi = getCalendarApi();
        calendarApi === null || calendarApi === void 0 ? void 0 : calendarApi.batchRendering(()=>{
            calendarApi.gotoDate((0, _datefns.subDays)(date, 15));
            calendarApi.scrollToTime({
                days: 15
            });
            const { activeStart, activeEnd } = calendarApi.view;
            handleChangeCalendar(activeStart, activeEnd, 0);
        });
    }, [
        getCalendarApi,
        handleChangeCalendar
    ]);
    const handleMove = (0, _react.useCallback)((direction)=>{
        const calendarApi = getCalendarApi();
        if (calendarApi) {
            const { activeStart } = calendarApi.view;
            if (direction === "next") {
                calendarApi.batchRendering(()=>{
                    const newMonths = (0, _datefns.addMonths)(activeStart, 1);
                    if ((0, _datefns.differenceInMonths)(newMonths, new Date()) < 12 * _constant.MAX_NUMBER_OF_NEXT_YEARS) {
                        calendarApi.gotoDate((0, _datefns.addMonths)(activeStart, 1));
                        calendarApi.scrollToTime({
                            days: numberOfDatesFromCurrentDate
                        });
                        const { activeStart: newActiveStart, activeEnd: newActiveEnd } = calendarApi.view;
                        handleChangeCalendar(newActiveStart, newActiveEnd, 0);
                    }
                });
            } else if (direction === "prev") {
                calendarApi.batchRendering(()=>{
                    calendarApi.gotoDate((0, _datefns.subMonths)(activeStart, 1));
                    calendarApi.scrollToTime({
                        days: numberOfDatesFromCurrentDate
                    });
                    const { activeStart: newActiveStart, activeEnd: newActiveEnd } = calendarApi.view;
                    handleChangeCalendar(newActiveStart, newActiveEnd, 0);
                });
            } else if (direction === "today") {
                calendarApi.batchRendering(()=>{
                    calendarApi.gotoDate((0, _datefns.subDays)(new Date(), 15));
                    calendarApi.scrollToTime({
                        days: numberOfDatesFromCurrentDate
                    });
                    const { activeStart: newActiveStart, activeEnd: newActiveEnd } = calendarApi.view;
                    handleChangeCalendar(newActiveStart, newActiveEnd, 0);
                });
            }
        }
    }, [
        getCalendarApi,
        handleChangeCalendar,
        numberOfDatesFromCurrentDate
    ]);
    (0, _react.useEffect)(()=>{
        if (!calendarDom) {
            return;
        }
        const scroller = getScroller(calendarDom);
        if (!scroller) {
            return;
        }
        function handleScrollEvent(event) {
            if (!event.target) {
                return;
            }
            onDateChangeAlongWithScroll(event);
            navigateCalendar(event);
        }
        scroller.addEventListener("scroll", handleScrollEvent);
        if ((0, _utils.checkIsMacDevice)()) {
            const scrollerMacOs1 = calendarDom.querySelector("tbody td:nth-child(3) .fc-scroller");
            if (scrollerMacOs1) {
                scrollerMacOs1.className += " fc-scroller-hide-scroll";
            }
            const scrollerMacOs2 = calendarDom.querySelector("thead th:nth-child(3) .fc-scroller");
            if (scrollerMacOs2) {
                scrollerMacOs2.className += " fc-scroller-hide-scroll";
            }
        }
        return ()=>{
            scroller.removeEventListener("scroll", handleScrollEvent);
        };
    }, [
        calendarDom,
        navigateCalendar,
        onDateChangeAlongWithScroll
    ]);
    (0, _react.useEffect)(()=>{
        if (!calendarDom) {
            return;
        }
        const scroller = getScroller(calendarDom);
        function mousedownEvent() {
            scrollerClickedRef.current = true;
        }
        function mouseupEvent() {
            scrollerClickedRef.current = false;
        }
        if (scroller) {
            scroller.addEventListener("mousedown", mousedownEvent);
            scroller.addEventListener("mouseup", mouseupEvent);
            const { clientWidth } = scroller;
            const numberOfDatesOnView = Math.round(clientWidth / slotMinWidth);
            setDays(Math.max(numberOfDatesOnView + 40, 45));
        }
        return ()=>{
            if (scroller) {
                scroller.removeEventListener("mousedown", mousedownEvent);
                scroller.removeEventListener("mouseup", mouseupEvent);
            }
        };
    }, [
        calendarDom,
        slotMinWidth
    ]);
    (0, _react.useEffect)(()=>{
        firstLoadTimeout(); // wait multicalendar first load done.
    }, [
        firstLoadTimeout
    ]);
    (0, _react.useEffect)(()=>{
        return ()=>{
            throttleCalendarMove.cancel();
        };
    }, [
        throttleCalendarMove
    ]);
    (0, _react.useEffect)(()=>{
        document.body.style.overscrollBehaviorX = "none";
        return ()=>{
            document.body.style.overscrollBehaviorX = "auto";
        };
    }, []);
    (0, _react.useEffect)(()=>{
        const calendar = document.querySelector(`.${CLASSNAME_WRAPPER}`);
        if (calendar) {
            setCalendarDom(calendar);
        }
    }, []);
    (0, _react.useEffect)(()=>{
        return ()=>{
            if (scrollTimeoutIdRef.current) {
                clearTimeout(scrollTimeoutIdRef.current);
            }
        };
    }, []);
    return [
        days,
        handleMoveToDate,
        handleMove
    ];
}
