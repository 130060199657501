"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "StarFilledIcon", {
    enumerable: true,
    get: function() {
        return StarFilledIcon;
    }
});
const _starfillediconsvg = require("../../../assets/star-filled-icon.svg");
const _iconMaker = require("./iconMaker");
const StarFilledIcon = (0, _iconMaker.iconMaker)(_starfillediconsvg.ReactComponent, "EyeIcon");
