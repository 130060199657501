"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _datefns = require("date-fns");
const userTimelineSendTimeDistance = (timeline)=>{
    const sendDate = timeline === null || timeline === void 0 ? void 0 : timeline.sendDate;
    if (!sendDate) {
        return;
    }
    const today = new Date();
    const sendDateInDate = (0, _datefns.parseISO)(sendDate);
    const isPast = (0, _datefns.isAfter)(today, sendDateInDate);
    const durationFormatted = (0, _datefns.formatDistance)(today, sendDateInDate, {
        includeSeconds: false
    });
    return isPast ? `Should have sent ${durationFormatted} ago` : `Sends in ${durationFormatted}`;
};
const _default = userTimelineSendTimeDistance;
