"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "removeUndefinedValuesFromObject", {
    enumerable: true,
    get: function() {
        return removeUndefinedValuesFromObject;
    }
});
const removeUndefinedValuesFromObject = (obj)=>{
    Object.keys(obj).forEach((key)=>obj[key] === undefined && delete obj[key]);
    return obj;
};
