"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    BedTypes: function() {
        return BedTypes;
    },
    CheckInMethods: function() {
        return CheckInMethods;
    },
    ImageTags: function() {
        return ImageTags;
    },
    PropertyTypes: function() {
        return PropertyTypes;
    }
});
const PropertyTypes = [
    {
        name: "PCT101",
        description: "Townhome"
    },
    {
        name: "PCT102",
        description: "Single Room"
    },
    {
        name: "PCT103",
        description: "Double Room"
    },
    {
        name: "PCT104",
        description: "Twin"
    },
    {
        name: "PCT105",
        description: "Twin/Double"
    },
    {
        name: "PCT106",
        description: "Triple Room"
    },
    {
        name: "PCT107",
        description: "Quadruple"
    },
    {
        name: "PCT108",
        description: "Family"
    },
    {
        name: "PCT109",
        description: "Suite"
    },
    {
        name: "PCT110",
        description: "Studio"
    },
    {
        name: "PCT111",
        description: "Bungalow"
    },
    {
        name: "PCT112",
        description: "Private room"
    },
    {
        name: "PCT113",
        description: "Shared room"
    },
    {
        name: "PCT114",
        description: "Cottage"
    },
    {
        name: "PCT115",
        description: "Apart Hotel"
    },
    {
        name: "PCT116",
        description: "Narrow boat"
    },
    {
        name: "PCT117",
        description: "Riad"
    },
    {
        name: "PCT118",
        description: "Shepherd Hut"
    },
    {
        name: "PCT119",
        description: "Tipi"
    },
    {
        name: "PCT12",
        description: "Cruise"
    },
    {
        name: "PCT120",
        description: "Tower"
    },
    {
        name: "PCT121",
        description: "Tree house"
    },
    {
        name: "PCT122",
        description: "Trullo"
    },
    {
        name: "PCT123",
        description: "Watermill"
    },
    {
        name: "PCT124",
        description: "Windmill"
    },
    {
        name: "PCT125",
        description: "Yacht"
    },
    {
        name: "PCT126",
        description: "Yurt"
    },
    {
        name: "PCT127",
        description: "Log Cabin"
    },
    {
        name: "PCT128",
        description: "Penthouse"
    },
    {
        name: "PCT14",
        description: "Ferry"
    },
    {
        name: "PCT15",
        description: "Guest farm"
    },
    {
        name: "PCT16",
        description: "Guest house limited service"
    },
    {
        name: "PCT18",
        description: "Holiday resort"
    },
    {
        name: "PCT19",
        description: "Hostel"
    },
    {
        name: "PCT20",
        description: "Hotel"
    },
    {
        name: "PCT21",
        description: "Inn"
    },
    {
        name: "PCT22",
        description: "Lodge"
    },
    {
        name: "PCT23",
        description: "Meeting resort"
    },
    {
        name: "PCT25",
        description: "Mobile-home"
    },
    {
        name: "PCT26",
        description: "Monastery"
    },
    {
        name: "PCT27",
        description: "Motel"
    },
    {
        name: "PCT28",
        description: "Ranch"
    },
    {
        name: "PCT29",
        description: "Residential apartment"
    },
    {
        name: "PCT3",
        description: "Apartment"
    },
    {
        name: "PCT30",
        description: "Resort"
    },
    {
        name: "PCT31",
        description: "Sailing ship"
    },
    {
        name: "PCT32",
        description: "Self catering accommodation"
    },
    {
        name: "PCT33",
        description: "Tent"
    },
    {
        name: "PCT34",
        description: "Vacation home"
    },
    {
        name: "PCT35",
        description: "Villa"
    },
    {
        name: "PCT36",
        description: "Wildlife reserve"
    },
    {
        name: "PCT37",
        description: "Castle"
    },
    {
        name: "PCT4",
        description: "Bed and breakfast"
    },
    {
        name: "PCT40",
        description: "Pension"
    },
    {
        name: "PCT41",
        description: "Ski Chalet"
    },
    {
        name: "PCT44",
        description: "Boatel"
    },
    {
        name: "PCT45",
        description: "Boutique"
    },
    {
        name: "PCT46",
        description: "Efficiency/studio"
    },
    {
        name: "PCT5",
        description: "Cabin or bungalow"
    },
    {
        name: "PCT50",
        description: "Recreational vehicle park"
    },
    {
        name: "PCT51",
        description: "Charm hotel"
    },
    {
        name: "PCT52",
        description: "Manor"
    },
    {
        name: "PCT6",
        description: "Campground"
    },
    {
        name: "PCT7",
        description: "Chalet"
    },
    {
        name: "PCT8",
        description: "Condominium"
    }
];
const CheckInMethods = [
    {
        name: "doorman",
        description: ""
    },
    {
        name: "lock_box",
        description: ""
    },
    {
        name: "smart_lock",
        description: ""
    },
    {
        name: "keypad",
        description: ""
    },
    {
        name: "in_person_meet",
        description: ""
    },
    {
        name: "other",
        description: ""
    },
    {
        name: "front_desk",
        description: ""
    },
    {
        name: "secret_spot",
        description: ""
    },
    {
        name: "instruction_contact_us",
        description: ""
    }
];
const ImageTags = [
    {
        Key: "1",
        Value: "Shower"
    },
    {
        Key: "2",
        Value: "Toilet"
    },
    {
        Key: "3",
        Value: "Property building"
    },
    {
        Key: "4",
        Value: "patio"
    },
    {
        Key: "5",
        Value: "Nearby landmark"
    },
    {
        Key: "6",
        Value: "Staff"
    },
    {
        Key: "7",
        Value: "Restaurant/places to eat"
    },
    {
        Key: "8",
        Value: "Communal lounge/ TV room"
    },
    {
        Key: "10",
        Value: "Facade/entrance"
    },
    {
        Key: "43",
        Value: "People"
    },
    {
        Key: "11",
        Value: "Spring"
    },
    {
        Key: "13",
        Value: "Bed"
    },
    {
        Key: "14",
        Value: "Off site"
    },
    {
        Key: "37",
        Value: "Food close-up"
    },
    {
        Key: "41",
        Value: "Day"
    },
    {
        Key: "42",
        Value: "Night"
    },
    {
        Key: "50",
        Value: "Property logo or sign"
    },
    {
        Key: "55",
        Value: "Neighbourhood"
    },
    {
        Key: "61",
        Value: "Natural landscape"
    },
    {
        Key: "70",
        Value: "Activities"
    },
    {
        Key: "74",
        Value: "Bird's eye view"
    },
    {
        Key: "81",
        Value: "Winter"
    },
    {
        Key: "82",
        Value: "Summer"
    },
    {
        Key: "87",
        Value: "BBQ facilities"
    },
    {
        Key: "89",
        Value: "Billiard"
    },
    {
        Key: "90",
        Value: "Bowling"
    },
    {
        Key: "94",
        Value: "Casino"
    },
    {
        Key: "95",
        Value: "Place of worship"
    },
    {
        Key: "96",
        Value: "Children play ground"
    },
    {
        Key: "97",
        Value: "Darts"
    },
    {
        Key: "100",
        Value: "Fishing"
    },
    {
        Key: "102",
        Value: "Game Room"
    },
    {
        Key: "103",
        Value: "Garden"
    },
    {
        Key: "104",
        Value: "Golf course"
    },
    {
        Key: "106",
        Value: "Horse-riding"
    },
    {
        Key: "107",
        Value: "Hot Spring Bath"
    },
    {
        Key: "141",
        Value: "Table tennis"
    },
    {
        Key: "108",
        Value: "Hot Tub"
    },
    {
        Key: "112",
        Value: "Karaoke"
    },
    {
        Key: "113",
        Value: "Library"
    },
    {
        Key: "114",
        Value: "Massage"
    },
    {
        Key: "115",
        Value: "Minigolf"
    },
    {
        Key: "116",
        Value: "Nightclub / DJ"
    },
    {
        Key: "124",
        Value: "Sauna"
    },
    {
        Key: "125",
        Value: "On-site shops"
    },
    {
        Key: "128",
        Value: "Ski School"
    },
    {
        Key: "131",
        Value: "Skiing"
    },
    {
        Key: "137",
        Value: "Squash"
    },
    {
        Key: "133",
        Value: "Snorkeling"
    },
    {
        Key: "134",
        Value: "Solarium"
    },
    {
        Key: "143",
        Value: "Steam room"
    },
    {
        Key: "153",
        Value: "Bathroom"
    },
    {
        Key: "154",
        Value: "TV and multimedia"
    },
    {
        Key: "155",
        Value: "Coffee/tea facilities"
    },
    {
        Key: "156",
        Value: "View (from property/room)"
    },
    {
        Key: "157",
        Value: "Balcony/Terrace"
    },
    {
        Key: "158",
        Value: "Kitchen or kitchenette"
    },
    {
        Key: "159",
        Value: "Living room"
    },
    {
        Key: "160",
        Value: "Lobby or reception"
    },
    {
        Key: "161",
        Value: "Lounge or bar"
    },
    {
        Key: "164",
        Value: "Spa and wellness centre/facili"
    },
    {
        Key: "165",
        Value: "Fitness centre/facilities"
    },
    {
        Key: "167",
        Value: "Food and drinks"
    },
    {
        Key: "172",
        Value: "Other"
    },
    {
        Key: "173",
        Value: "Photo of the whole room"
    },
    {
        Key: "177",
        Value: "Business facilities"
    },
    {
        Key: "178",
        Value: "Banquet/Function facilities"
    },
    {
        Key: "179",
        Value: "Decorative detail"
    },
    {
        Key: "182",
        Value: "Seating area"
    },
    {
        Key: "183",
        Value: "Floor plan"
    },
    {
        Key: "184",
        Value: "Dining area"
    },
    {
        Key: "185",
        Value: "Beach"
    },
    {
        Key: "186",
        Value: "Aqua park"
    },
    {
        Key: "187",
        Value: "Tennis court"
    },
    {
        Key: "188",
        Value: "Windsurfing"
    },
    {
        Key: "189",
        Value: "Canoeing"
    },
    {
        Key: "190",
        Value: "Hiking"
    },
    {
        Key: "191",
        Value: "Cycling"
    },
    {
        Key: "192",
        Value: "Diving"
    },
    {
        Key: "193",
        Value: "Kids's club"
    },
    {
        Key: "194",
        Value: "Evening entertainment"
    },
    {
        Key: "197",
        Value: "Logo/Certificate/Sign"
    },
    {
        Key: "198",
        Value: "Animals"
    },
    {
        Key: "199",
        Value: "Bedroom"
    },
    {
        Key: "204",
        Value: "Communal kitchen"
    },
    {
        Key: "205",
        Value: "Autumn"
    },
    {
        Key: "240",
        Value: "On site"
    },
    {
        Key: "241",
        Value: "Meeting/conference room"
    },
    {
        Key: "242",
        Value: "Food"
    },
    {
        Key: "245",
        Value: "Text overlay"
    },
    {
        Key: "246",
        Value: "Pets"
    },
    {
        Key: "247",
        Value: "Guests"
    },
    {
        Key: "248",
        Value: "City view"
    },
    {
        Key: "249",
        Value: "Garden view"
    },
    {
        Key: "250",
        Value: "Lake view"
    },
    {
        Key: "251",
        Value: "Landmark view"
    },
    {
        Key: "252",
        Value: "Mountain view"
    },
    {
        Key: "253",
        Value: "Pool view"
    },
    {
        Key: "254",
        Value: "River view"
    },
    {
        Key: "255",
        Value: "Sea view"
    },
    {
        Key: "256",
        Value: "Street view"
    },
    {
        Key: "257",
        Value: "Area and facilities"
    },
    {
        Key: "258",
        Value: "Supermarket/grocery shop"
    },
    {
        Key: "259",
        Value: "Shopping Area"
    },
    {
        Key: "260",
        Value: "Swimming pool"
    },
    {
        Key: "261",
        Value: "Sports"
    },
    {
        Key: "262",
        Value: "Entertainment"
    },
    {
        Key: "263",
        Value: "Meals"
    },
    {
        Key: "264",
        Value: "Breakfast"
    },
    {
        Key: "265",
        Value: "Continental breakfast"
    },
    {
        Key: "266",
        Value: "Buffet breakfast"
    },
    {
        Key: "267",
        Value: "Asian breakfast"
    },
    {
        Key: "268",
        Value: "Italian breakfast"
    },
    {
        Key: "269",
        Value: "English/Irish breakfast"
    },
    {
        Key: "270",
        Value: "American breakfast"
    },
    {
        Key: "271",
        Value: "Lunch"
    },
    {
        Key: "272",
        Value: "Dinner"
    },
    {
        Key: "273",
        Value: "Drinks"
    },
    {
        Key: "274",
        Value: "Alcoholic drinks"
    },
    {
        Key: "275",
        Value: "Non alcoholic drinks"
    },
    {
        Key: "276",
        Value: "Seasons"
    },
    {
        Key: "277",
        Value: "Time of day"
    },
    {
        Key: "278",
        Value: "Location"
    },
    {
        Key: "279",
        Value: "Sunrise"
    },
    {
        Key: "280",
        Value: "Sunset"
    },
    {
        Key: "281",
        Value: "children"
    },
    {
        Key: "282",
        Value: "young children"
    },
    {
        Key: "283",
        Value: "older children"
    },
    {
        Key: "284",
        Value: "group of guests"
    },
    {
        Key: "285",
        Value: "cot"
    },
    {
        Key: "286",
        Value: "bunk bed"
    },
    {
        Key: "287",
        Value: "Certificate/Award"
    },
    {
        Key: "288",
        Value: "ADAM"
    },
    {
        Key: "289",
        Value: "Open Air Bath"
    },
    {
        Key: "290",
        Value: "Public Bath"
    },
    {
        Key: "291",
        Value: "Family"
    }
];
const BedTypes = [
    {
        value: "RMA58",
        label: "King bed"
    },
    {
        value: "RMA86",
        label: "Queen bed"
    },
    {
        value: "RMA102",
        label: "Sofa bed"
    },
    {
        value: "RMA113",
        label: "Twin bed"
    },
    {
        value: "RMA200",
        label: "Futon"
    },
    {
        value: "RMA201",
        label: "Murphy bed"
    },
    {
        value: "RMA203",
        label: "Single bed"
    },
    {
        value: "RMA6032",
        label: "Bunk bed"
    },
    {
        value: "RMA33",
        label: "Double bed"
    },
    {
        value: "RMA26",
        label: "Cribs"
    },
    {
        value: "RMA6038",
        label: "Extra bed"
    },
    {
        value: "RMA6118",
        label: "Couch"
    },
    {
        value: "RMA6119",
        label: "Air mattress"
    },
    {
        value: "RMA6120",
        label: "Floor mattress"
    },
    {
        value: "RMA6121",
        label: "Toddler bed"
    },
    {
        value: "RMA6122",
        label: "Hammock"
    }
];
