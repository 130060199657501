"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ClockThreeIcon", {
    enumerable: true,
    get: function() {
        return ClockThreeIcon;
    }
});
const _clockthreesvg = require("../../../assets/clock-three.svg");
const _iconMaker = require("./iconMaker");
const ClockThreeIcon = (0, _iconMaker.iconMaker)(_clockthreesvg.ReactComponent, "ClockThreeIcon");
