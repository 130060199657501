"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
function useImageUploadStyle(size) {
    return (0, _react.useMemo)(()=>{
        const imageUploadPreviewSizeMap = {
            sm: {
                width: "48px",
                aspectRatio: 1,
                padding: 1.5,
                borderRadius: "6px",
                loadingSize: "16px",
                textProgressSize: "2xs",
                closeProgressIconSize: "8px"
            },
            md: {
                width: "165px",
                aspectRatio: 1.5,
                padding: 2,
                borderRadius: "8px",
                loadingSize: "20px",
                textProgressSize: "xs",
                closeProgressIconSize: "sm"
            },
            lg: {
                width: "270px",
                aspectRatio: 1.5,
                padding: 3,
                borderRadius: "4px",
                loadingSize: "24px",
                textProgressSize: "sm",
                closeProgressIconSize: "sm"
            }
        };
        return imageUploadPreviewSizeMap[size];
    }, [
        size
    ]);
}
const _default = useImageUploadStyle;
