"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "LogoutIcon", {
    enumerable: true,
    get: function() {
        return LogoutIcon;
    }
});
const _logouticonsvg = require("../../../assets/logout-icon.svg");
const _iconMaker = require("./iconMaker");
const LogoutIcon = (0, _iconMaker.iconMaker)(_logouticonsvg.ReactComponent, "LogoutIcon");
