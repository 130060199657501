"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    base64ImagePrefix: function() {
        return base64ImagePrefix;
    },
    buildPostFromAirbnbReservationPost: function() {
        return buildPostFromAirbnbReservationPost;
    },
    buildPosts: function() {
        return buildPosts;
    },
    filterDuplicatedItems: function() {
        return filterDuplicatedItems;
    },
    filterOutFillerMessages: function() {
        return filterOutFillerMessages;
    },
    postFillerPrefix: function() {
        return postFillerPrefix;
    },
    sortPosts: function() {
        return sortPosts;
    }
});
const _channel = require("../../../../../core/src/shared/model/channel");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const postFillerPrefix = "addedAt-";
const base64ImagePrefix = "data:image/png;base64,";
function buildPostFromAirbnbReservationPost(post, thread) {
    var _thread_posts;
    // use the attachments urls from channel thread
    const threadPost = (_thread_posts = thread.posts) === null || _thread_posts === void 0 ? void 0 : _thread_posts.find((elem)=>elem.externalID === post.externalID);
    if (threadPost === null || threadPost === void 0 ? void 0 : threadPost.attachments) {
        return {
            id: post._id,
            date: post.sentTimestamp,
            post: {
                type: "post",
                data: _object_spread_props(_object_spread({}, post), {
                    attachments: threadPost.attachments.map((elem)=>({
                            _id: "",
                            publicId: "",
                            url: elem
                        }))
                })
            }
        };
    }
}
const buildPosts = (reservationID, source, baseUrl, posts, thread)=>{
    return posts.map((post)=>{
        var _post_attachments;
        // Airbnb
        if (thread && "posts" in thread && thread.posts && post.externalID && ((_post_attachments = post.attachments) === null || _post_attachments === void 0 ? void 0 : _post_attachments.length)) {
            // use the attachments urls from channel thread
            const result = buildPostFromAirbnbReservationPost(post, thread);
            if (result) {
                return result;
            }
        }
        // Booking
        if ((source === _channel.Channel.Booking || source === _channel.Channel.HomeAway) && post.attachments) {
            const attachments = post.attachments.reduce((arr, elem)=>{
                // Do nothing if url is existing
                if (elem.url) {
                    arr.push(elem);
                    return arr;
                }
                // treat `publicId` as `attachmentID`
                const attachmentID = elem.publicId;
                arr.push({
                    _id: "",
                    url: `${baseUrl}/getAttachment/${reservationID}/${attachmentID}`,
                    publicId: attachmentID
                });
                return arr;
            }, []);
            return {
                id: post._id,
                date: post.sentTimestamp,
                post: {
                    type: "post",
                    data: _object_spread_props(_object_spread({}, post), {
                        attachments
                    })
                }
            };
        }
        return {
            id: post._id,
            date: post.sentTimestamp,
            post: {
                type: "post",
                data: _object_spread({}, post, post.attachments && {
                    attachments: post.attachments.filter((elem)=>elem.url)
                })
            }
        };
    });
};
function sortPosts(posts) {
    return posts ? posts.sort((a, b)=>{
        const dateA = new Date(a.sentTimestamp).getTime();
        const dateB = new Date(b.sentTimestamp).getTime();
        return dateA > dateB ? -1 : 1;
    }) : [];
}
const filterOutFillerMessages = (items)=>items.filter((elem)=>!elem.id.startsWith(postFillerPrefix));
const filterDuplicatedItems = (prevOnes, addedOnes)=>{
    const prevIDs = prevOnes.map((elem)=>elem.id);
    return addedOnes.filter((addedOne)=>{
        return !prevIDs.includes(addedOne.id);
    });
};
