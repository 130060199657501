"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const shadows = {
    // box-shadow: 0px 1px 1px 0px #94A3B81A;
    textField: {
        shadowColor: "#94A3B8",
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowOpacity: 0.1,
        shadowRadius: 1,
        elevation: 0
    },
    // box-shadow: 0px 3px 8px 0px #0612211A;
    subtleShadow: {
        shadowColor: "#061221",
        shadowOffset: {
            width: 0,
            height: 3
        },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 0
    },
    // box-shadow: 0px 0px 0px 2px #E2E8F080;
    spread: {
        shadowColor: "#E2E8F0",
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.5,
        shadowRadius: 0,
        elevation: 2
    }
};
const _default = shadows;
