"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    entityTypes: function() {
        return entityTypes;
    },
    feeTypes: function() {
        return feeTypes;
    },
    taxTypes: function() {
        return taxTypes;
    },
    units: function() {
        return units;
    },
    valueTypes: function() {
        return valueTypes;
    }
});
const entityTypes = [
    {
        value: "MANDATORY",
        label: "Mandatory"
    },
    {
        value: "MANDATORY_PAL",
        label: "Mandatory Pal"
    },
    {
        value: "OPTIONAL",
        label: "Optional"
    }
];
const feeTypes = [
    {
        value: "DEPOSIT",
        label: "Deposit"
    },
    {
        value: "GENERAL",
        label: "General"
    },
    {
        value: "PET_FEE",
        label: "Pet fee"
    }
];
const taxTypes = [
    {
        value: "TAXABLE",
        label: "Taxable"
    },
    {
        value: "NOT_TAXABLE",
        label: "Not taxable"
    }
];
const valueTypes = [
    {
        value: "FLAT",
        label: "Amount"
    },
    {
        value: "PERCENT",
        label: "Percent"
    }
];
const units = [
    {
        value: "PER_DAY",
        label: "Per day"
    },
    {
        value: "PER_PERSON",
        label: "Per person"
    },
    {
        value: "PER_STAY",
        label: "Per stay"
    },
    {
        value: "PER_DAY_PER_PERSON",
        label: "Per day per person"
    },
    {
        value: "PER_DAY_PER_PERSON_EXTRA",
        label: "Per day per person extra"
    }
];
