"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useChannelLogoVariant", {
    enumerable: true,
    get: function() {
        return useChannelLogoVariant;
    }
});
const _react = require("react");
function useChannelLogoVariant(size) {
    return (0, _react.useMemo)(()=>{
        const channelLogoVariants = {
            "2xs": {
                logoSize: 8,
                textSize: "xs",
                space: 1
            },
            xs: {
                logoSize: 12,
                textSize: "xs",
                space: 1
            },
            sm: {
                logoSize: 16,
                textSize: "sm",
                space: 1
            },
            md: {
                logoSize: 20,
                textSize: "md",
                space: 1
            },
            lg: {
                logoSize: 24,
                textSize: "lg",
                space: 1
            },
            xl: {
                logoSize: 32,
                textSize: "lg",
                space: 1
            },
            "2xl": {
                logoSize: 36,
                textSize: "lg",
                space: 1
            },
            "3xl": {
                logoSize: 48,
                textSize: "lg",
                space: 1
            }
        };
        return channelLogoVariants[size];
    }, [
        size
    ]);
}
