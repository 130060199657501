"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _react = require("react");
function useCopyToClipboard() {
    const [copiedText, setCopiedText] = (0, _react.useState)(null);
    const copy = async (text)=>{
        var _navigator;
        if (!((_navigator = navigator) === null || _navigator === void 0 ? void 0 : _navigator.clipboard)) {
            console.warn("Clipboard not supported");
            return false;
        }
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            return true;
        } catch (error) {
            setCopiedText(null);
            return false;
        }
    };
    return [
        copiedText,
        copy
    ];
}
const _default = useCopyToClipboard;
