"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "ArrowTopRightIcon", {
    enumerable: true,
    get: function() {
        return ArrowTopRightIcon;
    }
});
const _arrowtoprightsvg = require("../../../assets/arrow-top-right.svg");
const _iconMaker = require("./iconMaker");
const ArrowTopRightIcon = (0, _iconMaker.iconMaker)(_arrowtoprightsvg.ReactComponent, "ArrowTopRightIcon", "stroke");
