"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _frontend = require("../../../../../frontend/src/index");
const _react = require("react");
const _reacthookform = require("react-hook-form");
function useHandleArrayField(name) {
    const { control } = (0, _reacthookform.useFormContext)();
    const { fields, append, update, remove } = (0, _reacthookform.useFieldArray)({
        control,
        name
    });
    const [obj, setObj] = (0, _react.useState)(undefined);
    const { isOpen, onOpen, onClose } = (0, _frontend.useDisclose)();
    const idxRef = (0, _react.useRef)(-1);
    const onEdit = (0, _react.useCallback)((tax, idx)=>{
        setObj(tax);
        onOpen();
        if (typeof idx === "number") {
            idxRef.current = idx;
        }
    }, [
        onOpen
    ]);
    const onCancel = (0, _react.useCallback)(()=>{
        setObj(undefined);
        idxRef.current = -1;
        onClose();
    }, [
        onClose
    ]);
    const onSave = (0, _react.useCallback)((item)=>{
        const idx = idxRef.current;
        if (idx > -1) {
            // this doesn't look nice but there is no better way
            update(idx, item);
        } else {
            append(item);
        }
        onClose();
        setObj(undefined);
        idxRef.current = -1;
    }, [
        update,
        append,
        onClose
    ]);
    return {
        isOpen,
        obj,
        fields,
        index: idxRef.current,
        onOpen,
        onClose,
        onEdit,
        onRemove: remove,
        onSave,
        onCancel
    };
}
const _default = useHandleArrayField;
