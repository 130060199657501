"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "DashboardIcon", {
    enumerable: true,
    get: function() {
        return DashboardIcon;
    }
});
const _dashboardiconsvg = require("../../../assets/dashboard-icon.svg");
const _iconMaker = require("./iconMaker");
const DashboardIcon = (0, _iconMaker.iconMaker)(_dashboardiconsvg.ReactComponent, "DashboardIcon");
