"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    defaultCheckInHour: function() {
        return defaultCheckInHour;
    },
    defaultCheckOutHour: function() {
        return defaultCheckOutHour;
    },
    sanitizeListing: function() {
        return sanitizeListing;
    },
    sanitizeListings: function() {
        return sanitizeListings;
    }
});
const _utilslisting = require("../../../core/src/shared/utils/utils.listing");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
const defaultCheckInHour = 16;
const defaultCheckOutHour = 11;
function sanitizeListing(raw) {
    const listing = _object_spread({}, raw);
    var _listing_airbnbName;
    listing.airbnbName = (_listing_airbnbName = listing.airbnbName) !== null && _listing_airbnbName !== void 0 ? _listing_airbnbName : "";
    if (!listing.timeZone) {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        listing.timeZone = timeZone;
    }
    listing.name = (0, _utilslisting.getListingName)(listing);
    listing.currencySymbol = listing.currencySymbol || "";
    var _listing_airbnbCheckInTime;
    listing.checkInTime = (_listing_airbnbCheckInTime = listing.airbnbCheckInTime) !== null && _listing_airbnbCheckInTime !== void 0 ? _listing_airbnbCheckInTime : defaultCheckInHour;
    var _listing_airbnbCheckOutTime;
    listing.checkOutTime = (_listing_airbnbCheckOutTime = listing.airbnbCheckOutTime) !== null && _listing_airbnbCheckOutTime !== void 0 ? _listing_airbnbCheckOutTime : defaultCheckOutHour;
    var _listing_streetNumber;
    listing.street = `${(_listing_streetNumber = listing.streetNumber) !== null && _listing_streetNumber !== void 0 ? _listing_streetNumber : ""} ${listing.streetName}`.trim();
    var _listing_channels;
    listing.channels = (_listing_channels = listing.channels) !== null && _listing_channels !== void 0 ? _listing_channels : {};
    return listing;
}
function sanitizeListings(listings) {
    return listings.map(sanitizeListing);
}
